import { ChannelOperations } from "enums/channelStatus";
import {
  channelProps,
  channelsProps,
  engineProps,
  IntialStateChannels,
} from "./type";
import {
  CREATE_CHANNEL,
  GET_CHANNELS,
  GET_ENGINES,
  GET_TYPE_ACTION_CHANNEL,
  UPDATE_TYPE_ACTION_CHANNEL,
  DELETE_CHANNEL,
  SELECTED_CHANNELS,
  CURRENT_CHANNEL,
  UPDATE_CHANNEL,
  UPDATE_SETTING_CHANNEL,
} from "./types";
import { updateChannels } from "./utils";

const initialState: IntialStateChannels = {
  engines: [],
  channels: [],
  typeActionChannel: ChannelOperations.GET,
  authLink: null,
  selectedChannels: [],
  currentChannel: null,
};

export default function channelReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;
  const engines: engineProps[] = state.engines;
  switch (type) {
    case GET_CHANNELS: {
      const channelsValues = updateChannels({
        engines,
        channels: [...payload],
      });
      return {
        ...state,
        channels: channelsValues,
      };
    }
    case GET_ENGINES:
      return {
        ...state,
        engines: payload,
      };
    case CREATE_CHANNEL:
      return {
        ...state,
        authLink: payload,
      };
    case DELETE_CHANNEL: {
      return {
        ...state,
        channels: state.channels.map((item: channelsProps) =>
          item.enginesType === payload.engineType
            ? {
              ...item,
              channels: item.channels.filter((item: channelProps) => {
                return item.ID !== payload.ID;
              }),
            }
            : item
        ),
        selectedChannels: state.selectedChannels.filter(
          (item: channelProps) => {
            return item.ID !== payload.ID;
          }
        ),
      };
    }
    case GET_TYPE_ACTION_CHANNEL:
      return {
        ...state,
        typeActionChannel: payload,
      };
    case UPDATE_TYPE_ACTION_CHANNEL:
      return {
        ...state,
        typeActionChannel: payload,
      };
    case SELECTED_CHANNELS:
      return {
        ...state,
        selectedChannels: payload,
      };
    case CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: payload,
      };
    case UPDATE_CHANNEL:
      return {
        ...state,
      };
    case UPDATE_SETTING_CHANNEL:
      return {
        ...state,
        channels: [...state.channels].map((item: channelsProps) =>
          item.enginesType === payload.engineType && item.engineID === payload.engineID
            ? {
              ...item,
              channels: item.channels.map((ch: channelProps) => {
                if (ch.ID === payload.channelID) {
                  delete payload?.channelID
                  payload["name"] = payload?.channelName;
                  delete payload?.channelName
                  return { ...ch, ...payload }
                }
                return ch
              }),
            }
            : item
        ),
      };
    default:
      return state;
  }
}
