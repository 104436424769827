import Alert from "@mui/material/Alert";
import React, { useContext } from "react";
import { AlertTitle, Button, Collapse } from "@mui/material";
import { APP_UPDATED } from "redux/appSetting/types";
import { ConfigSelector } from "redux/appSetting/selector";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppContext } from "App";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { AppConfig } from "enums/config";

type Props = {};

const UpdateAlert: React.FC<Props> = () => {
  const [t, theme, , , , mobileMatch] = useContext(AppContext);

  const dispatch = useDispatch();

  const appUpdated = useSelector(
    (state) => ConfigSelector(state).appUpdated,
    shallowEqual
  );
  return appUpdated ? (
    <Collapse in={appUpdated}>
      <Alert
        icon={
          <SyncRoundedIcon
            fontSize="large"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        sx={{
          pt: "2em",
          bgcolor: theme.palette.whatsApp.recieverColor,
          borderRadius: `0 0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX}`,
          boxShadow: theme.palette.general.boxShadowWOW,
          position: "absolute",
          zIndex: 1000000,
          width: mobileMatch ? "100%" : "50%",
          maxWidth: "700px",
          left: 0,
          right: 0,
          mr: "auto",
          ml: "auto",
          color: theme.palette.general.boxShadowDark,
          borderTop: `5px solid ${theme.palette.primary.main}`,
        }}
        action={
          <Button
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: "1.1em",
            }}
            color="inherit"
            size="large"
            onClick={() => {
              dispatch({
                type: APP_UPDATED,
                payload: false,
              });

              window.location.reload();
            }}
          >
            {t("update")}
          </Button>
        }
      >
        <AlertTitle color={theme.palette.primary.main}>
          {t("new_update_found")}
        </AlertTitle>
        {t("click_update_to_install_it")}
      </Alert>
    </Collapse>
  ) : null;
};

export default UpdateAlert;
