import { keyBy } from "lodash";
import { CompanySetting } from "./selector";
import {
  CREATE_COMPANY_CUSTOM_STATUS,
  CREATE_SHIFT,
  DELETE_COMPANY_CUSTOM_STATUS,
  DELETE_SHIFT,
  GET_COMPANY,
  GET_SHIFT,
  UPDATE_COMPANY,
  UPDATE_SHIFT,
} from "./types";

const initialState: CompanySetting = {
  company: null,
  shifts: {},
  orginalShifts: [],
  onlineCustomStatus: [],
  offlineCustomStatus: [],
};

export default function companySettingReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_COMPANY: {
      return {
        ...state,
        company: payload,
      };
    }
    case GET_COMPANY:
      const { CustomStatuses } = payload || { CustomStatuses: [] };
      const onlineCustomStatus = CustomStatuses?.filter(
        (status: any) => status.type === 1
      );
      const offlineCustomStatus = CustomStatuses?.filter(
        (status: any) => status.type === 2
      );
      return {
        ...state,
        company: payload,
        onlineCustomStatus,
        offlineCustomStatus,
      };
    case GET_SHIFT: {
      let res = keyBy(payload, "id");
      return {
        ...state,
        shifts: res,
        orginalShifts: payload,
      };
    }
    case CREATE_SHIFT:
      return {
        ...state,
        shifts: { ...state.shifts, [payload?.id]: payload },
      };
    case DELETE_SHIFT: {
      let res = { ...state.shifts };
      delete res[payload];
      return {
        ...state,
        shifts: res,
      };
    }
    case UPDATE_SHIFT:
      return {
        ...state,
        shifts: { ...state.shifts, [payload?.id]: payload },
      };

    case CREATE_COMPANY_CUSTOM_STATUS:
      return state;

    case DELETE_COMPANY_CUSTOM_STATUS:
      return state;

    default:
      return state;
  }
}
