import { HttpRequestUserSpaceData } from "components/flow/type";
import { includes, isEmpty } from "lodash";
import { getMyChannels } from "redux/channels/actions";
import { errorHandling } from "utils/errorHandling";
import {
  requestCreateFlow,
  requestGetFlow,
  requestListFlow,
  requestUpdateFlow,
  returnCreateFlow,
  returnGetFlow,
  returnUpdateFlow,
  returnlistFlow,
} from "../../socketConfig/socketEvent";
import localize from "../../utils/localize";
import { loading, stopLoading, triggerAlert } from "../appSetting/actions";
import { flowData } from "./type";
import {
  ADD_ERRORS_RELATED_VARIABLES,
  ADD_FIELDS_ERRORS_REPLY,
  ADD_HTTP_REQUEST_USER_SPACE,
  ADD_NODES_CONNECTED_JUMP,
  ADD_NODES_CONNECTED_SUB_FLOW,
  ADD_NODES_CONNECTED_VARS,
  ADD_NODES_FIELDS_ERRORS,
  ADD_SUB_FLOW,
  CUSTOM_LOADING,
  CUSTOM_STOP_LOADING,
  DELETE_ERRORS_RELATED_VARIABLES,
  DELETE_NODES_CONNECTED_SUB_FLOW,
  DELETE_NODES_FIELDS_ERRORS,
  DELETE_RULE_UPDATE_CONNECTED_VARS,
  DELETE_SUB_FLOW,
  DISPLAY_ERROR_MODAL,
  DISPLAY_TEMPLATE_MODAL,
  EDIT_SUB_FLOW,
  ERRORS_RELATED_VARIABLES,
  FETCH_SUB_FLOWS,
  FLOW_CLEAR,
  FLOW_CREATE,
  FLOW_DELETE,
  FLOW_FETCH,
  FLOW_FILTER_TRIGGER,
  FLOW_SEARCH,
  FLOW_STATUS,
  FLOW_UPDATE,
  FLOW_UPDATE_STATUS,
  GET_HTTP_REQUEST_USER_SPACE,
  GET_ORDERED_NODES,
  NODES_CONNECTED_JUMP,
  NODES_CONNECTED_SUB_FLOW,
  NODES_CONNECTED_VARS,
  NODES_DELETE_CONNECTED_JUMP,
  NODES_DELETE_GROUP_CONNECTED_JUMP,
  NODES_FIELDS_ERRORS,
  NODES_GET,
  NODES_NOTIFIED,
  NODES_UPDATE,
  NODES_UPDATE_CONNECTED_JUMP,
  NODES_UPDATE_CONNECTED_VARS,
  STOP_DISPLAY_ERROR_MODAL,
  STOP_DISPLAY_TEMPLATE_MODAL,
  UPDATE_ALL_VARIABLES,
  UPDATE_FLOW_OPTIONS,
  UPDATE_HTTP_REQUEST_USER_SPACE,
  UPDATE_NODES_CONNECTED_SUB_FLOW,
  UPDATE_NODES_FIELDS_ERRORS,
  UPDATE_NODES_FIELDS_ERRORS_REPLY,
  UPDATE_ORDERED_NODES,
  UPDATE_ORDERED_NODES_SUB_FLOWS,
  UPDATE_SELECTED_CONDITION,
  UPDATE_SHOW_DRAWER,
} from "./types";

export const getListFlow = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    await requestListFlow();
    await returnlistFlow(
      (data: {
        Error: string;
        Flows: flowData[];
        SocketKey: string;
        Status: string;
        Warning?: string;
      }) => {
        if (isEmpty(data?.Error) && data?.Status === "SUCCESS") {
          dispatch({
            type: FLOW_STATUS,
            payload: { list: data?.Flows },
          });
        } else if (!isEmpty(data?.Warning)) {
          dispatch(triggerAlert(localize("update_flow_fail"), "warning"));
        } else {
          dispatch(triggerAlert(localize("get_list_flow_fail"), "error"));
        }
        dispatch(stopLoading());
        return;
      }
    );
  } catch (er: any) {
    errorHandling(er, dispatch, "get_list_flow_fail");
  }
};

export const createFlow =
  (
    Name = "",
    Description: string = "",
    Version: number,
    Status: number = 0,
    Payload: string = "",
    UIPayload: string = "",
    Trigger: string = "",
    Options: any = null
  ) =>
    async (dispatch: any) => {
      try {
        dispatch(loading());
        await requestCreateFlow(
          Name,
          Description,
          Version,
          Status,
          Payload,
          UIPayload,
          Trigger,
          Options
        );
        await returnCreateFlow(
          (data: {
            Error: string;
            Flow: flowData[];
            SocketKey: string;
            Status: string;
            Warning?: string;
          }) => {
            if (isEmpty(data?.Error) && data?.Status === "SUCCESS") {
              dispatch({
                type: FLOW_CREATE,
                payload: data?.Flow,
              });
              // dispatch({
              //   type: FLOW_FETCH,
              //   payload: data?.Flow,
              // });
              dispatch(triggerAlert(localize("create_flow_success"), "success"));
            } else if (!isEmpty(data?.Warning)) {
              dispatch(triggerAlert(localize("update_flow_fail"), "warning"));
            } else {
              dispatch(triggerAlert(localize("create_flow_fail"), "error"));
            }
            dispatch(stopLoading());
            return;
          }
        );
      } catch (er: any) {
        errorHandling(er, dispatch, "create_flow_fail");
      }
    };

export const getFlow =
  (key: string, Version: number, status: number) => async (dispatch: any) => {
    try {
      dispatch(loading());
      await requestGetFlow(key, Version, status);
      await returnGetFlow(
        (data: {
          Error: string;
          Flow: flowData[];
          SocketKey: string;
          Status: string;
          Warning?: string;
        }) => {
          if (isEmpty(data?.Error) && data?.Status === "SUCCESS") {
            dispatch({
              type: FLOW_FETCH,
              payload: data?.Flow,
            });
          } else if (!isEmpty(data?.Warning)) {
            dispatch(triggerAlert(localize("update_flow_fail"), "warning"));
          } else {
            dispatch(triggerAlert(localize("get_flow_fail"), "error"));
          }
          dispatch(stopLoading());
          return;
        }
      );
    } catch (er: any) {
      errorHandling(er, dispatch, "get_flow_fail");
    }
  };

export const getSubFlows = (data: any) => async (dispatch: any) => {
  try {
    // dispatch(loading());
    dispatch({
      type: FETCH_SUB_FLOWS,
      payload: data,
    });
  } catch (error) { }
};
export const deleteSubFlow = (subFlowId: string) => async (dispatch: any) => {
  try {
    // dispatch(loading());
    dispatch({
      type: DELETE_SUB_FLOW,
      payload: subFlowId,
    });
  } catch (error) { }
};
export const addSubFlow = (data: any) => async (dispatch: any) => {
  try {
    // dispatch(loading());
    dispatch({
      type: ADD_SUB_FLOW,
      payload: data,
    });
  } catch (error) { }
};
export const editSubFlow = (data: any) => async (dispatch: any) => {
  try {
    // dispatch(loading());
    dispatch({
      type: EDIT_SUB_FLOW,
      payload: data,
    });
  } catch (error) { }
};
export const clearCurrentFlow = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    dispatch({
      type: FLOW_CLEAR,
      payload: null,
    });
  } catch (error) { }
};

export const updateFlow =
  (
    key: string,
    Name: string,
    Description: string,
    Version: number,
    Status: number,
    Payload: string,
    UIPayload: string,
    Trigger: string = "",
    ChannelIDs: string = "",
    isDisplaymessgae = false,
    isUpdateFlow = false,
    Options: string | null = null
  ) =>
    async (dispatch: any) => {
      try {
        let displayOperationmessgae = isDisplaymessgae;
        displayOperationmessgae && dispatch(loading());
        await requestUpdateFlow(
          key,
          Name,
          Description,
          Version,
          Status,
          Payload,
          UIPayload,
          Trigger,
          ChannelIDs,
          Options
        );
        // if (displayOperationmessgae) {
        await returnUpdateFlow(
          (data: {
            Error: string;
            Flow: flowData;
            SocketKey: string;
            Status: string;
            Warning?: string;
          }) => {
            if (displayOperationmessgae) {
              if (
                isEmpty(data?.Error) &&
                includes(["SUCCESS", "WARNING"], data?.Status)
              ) {
                if (data?.Flow?.Status === 1) {
                  dispatch(getMyChannels());
                }
                if (isUpdateFlow) {
                  dispatch({
                    type: FLOW_UPDATE_STATUS,
                    payload: data?.Flow,
                  });
                }

                if (!isEmpty(data?.Warning) || data?.Status === "WARNING") {
                  dispatch(
                    triggerAlert(
                      data?.Warning || localize("update_flow_fail"),
                      "warning"
                    )
                  );
                } else {
                  dispatch(
                    triggerAlert(localize("update_flow_success"), "success")
                  );
                }
              } else {
                dispatch(triggerAlert(localize("update_flow_fail"), "error"));
              }
            } else if (!isEmpty(data?.Warning)) {
              dispatch(triggerAlert(localize("update_flow_fail"), "warning"));
            } else {
              dispatch(triggerAlert(localize("update_flow_fail"), "error"));
            }

            displayOperationmessgae = false;
            dispatch(stopLoading());
            return;
          }
        );
      } catch (er) {
        errorHandling(er, dispatch, "update_flow_fail");
      }
    };

export const updateFlowUI =
  (
    key: string,
    Name: string,
    Description: string,
    Version: number,
    Status: number,
    Payload: string,
    UIPayload: string,
    Trigger: string = "",
    ChannelIDs: string = "",
    Options: string | null = null,
    isUsedLoader = true
  ) =>
    async (dispatch: any) => {
      try {
        await requestUpdateFlow(
          key,
          Name,
          Description,
          Version,
          Status,
          Payload,
          UIPayload,
          Trigger,
          ChannelIDs,
          Options
        );
        await returnUpdateFlow(() => {
          isUsedLoader && dispatch(stopFlowLoading());
        });
      } catch (er: any) {
        errorHandling(er, dispatch, "auto_save_flow_fail");
      }
    };

export const deleteFlow =
  (
    key: string,
    Name: string,
    Description: string,
    Version: number,
    Status: number,
    Payload: any,
    UIPayload: any
  ) =>
    async (dispatch: any) => {
      try {
        dispatch(loading());
        await requestUpdateFlow(
          key,
          Name,
          Description,
          Version,
          Status,
          Payload,
          UIPayload
        );
        await returnUpdateFlow(
          (data: {
            Error: string;
            Flow: flowData[];
            SocketKey: string;
            Status: string;
            Warning?: string;
          }) => {
            if (isEmpty(data?.Error) && data?.Status === "SUCCESS") {
              dispatch({
                type: FLOW_DELETE,
                payload: key,
              });

              dispatch(getMyChannels());
              dispatch(triggerAlert(localize("delete_flow_success"), "success"));
            } else if (!isEmpty(data?.Warning)) {
              dispatch(triggerAlert(localize("delete_flow_fail"), "warning"));
            } else {
              dispatch(triggerAlert(localize("delete_flow_fail"), "error"));
            }
            dispatch(stopLoading());
            return;
          }
        );
      } catch (er: any) {
        errorHandling(er, dispatch, "delete_flow_fail");
      }
    };

export const updateFlowName =
  (
    key: string,
    Name: string,
    Description: string,
    Version: number,
    Status: number,
    Payload: string,
    UIPayload: string,
    Trigger: string = "",
    ChannelIDs: string = "",
    Options: string | null = null
  ) =>
    async (dispatch: any) => {
      try {
        await requestUpdateFlow(
          key,
          Name,
          Description,
          Version,
          Status,
          Payload,
          UIPayload,
          Trigger,
          ChannelIDs,
          Options
        );

        await returnUpdateFlow(
          (data: {
            Error: string;
            Flow: flowData;
            SocketKey: string;
            Status: string;
            Warning?: string;
          }) => {
            if (isEmpty(data?.Error) && data?.Status === "SUCCESS") {
              dispatch({
                type: FLOW_UPDATE,
                payload: data?.Flow,
              });

              dispatch(triggerAlert(localize("update_flow_success"), "success"));
            } else if (!isEmpty(data?.Warning)) {
              dispatch(triggerAlert(localize("update_flow_fail"), "warning"));
            } else {
              dispatch(triggerAlert(localize("update_flow_fail"), "error"));
            }
            dispatch(stopLoading());
            return;
          }
        );
      } catch (er: any) {
        errorHandling(er, dispatch, "auto_save_flow_fail");
      }
    };

export const searchFlowName = (name: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: FLOW_SEARCH,
      payload: name,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "auto_save_flow_fail");
  }
};

export const filterTriggerFlow =
  (triggers: string[]) => async (dispatch: any) => {
    try {
      dispatch({
        type: FLOW_FILTER_TRIGGER,
        payload: triggers,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "auto_save_flow_fail");
    }
  };

export const getObjectNodes = (nodes: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: NODES_GET,
      payload: nodes,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "auto_save_flow_fail");
  }
};

export const updateObjectNodes = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: NODES_UPDATE,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "auto_save_flow_fail");
  }
};

export const getNodesConnectedVars = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: NODES_CONNECTED_VARS,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "get_nodes_connected_vars_fail");
  }
};

export const addNodesConnectedVars = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_NODES_CONNECTED_VARS,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "add_nodes_connected_vars_fail");
  }
};

export const updateNodesConnectedVars =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: NODES_UPDATE_CONNECTED_VARS,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "update_nodes_connected_vars_fail");
    }
  };

export const deletRuleConnectedVars =
  (data: {
    ruleId: string;
    nodeConditionId: string;
    systemVarTitle: string;
    systemVarType: string;
  }) =>
    async (dispatch: any) => {
      try {
        dispatch({
          type: DELETE_RULE_UPDATE_CONNECTED_VARS,
          payload: data,
        });
      } catch (er: any) {
        errorHandling(er, dispatch, "delete_rule_fail");
      }
    };

export const getNodesNotified = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: NODES_NOTIFIED,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "get_notife_fail");
  }
};

export const getNodesConnectedJump = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: NODES_CONNECTED_JUMP,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "get_nodes_connected_jump_fail");
  }
};

export const addNodesConnectedJump = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_NODES_CONNECTED_JUMP,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "add_nodes_connected_jump_fail");
  }
};

export const updateNodesConnectedJump =
  (data: { currentNodeId: string; prevNodeId: string; jumpNodeId: string }) =>
    async (dispatch: any) => {
      try {
        dispatch({
          type: NODES_UPDATE_CONNECTED_JUMP,
          payload: data,
        });
      } catch (er: any) {
        errorHandling(er, dispatch, "update_nodes_connected_jump_fail");
      }
    };

export const deleteNodesGroupConnectedJump =
  (currentNodeId: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: NODES_DELETE_GROUP_CONNECTED_JUMP,
        payload: currentNodeId,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "delete_nodes_connected_jump_fail");
    }
  };

export const deleteNodesConnectedJump =
  (data: { currentNodeId: string; jumpNodeId: string }) =>
    async (dispatch: any) => {
      try {
        dispatch({
          type: NODES_DELETE_CONNECTED_JUMP,
          payload: data,
        });
      } catch (er: any) {
        errorHandling(er, dispatch, "delete_nodes_connected_jump_fail");
      }
    };

export const getHttpRequestUserSpace =
  (data: {
    httpRequestUserSpace: Record<
      string,
      Record<string, { title: string; id: string; type: string }[] | string>
    >;
    allVariables: any;
  }) =>
    async (dispatch: any) => {
      try {
        dispatch({
          type: GET_HTTP_REQUEST_USER_SPACE,
          payload: data,
        });
      } catch (er: any) {
        errorHandling(er, dispatch, "get_http_request_fail");
      }
    };
export const updateHttpRequestUserSpace =
  (
    data: {
      httpRequestUserSpace?: { [httpNodeId: string]: HttpRequestUserSpaceData };
      type?: string;
      allVariables?: any;
    } | null = null
  ) =>
    async (dispatch: any) => {
      try {
        dispatch({
          type: UPDATE_HTTP_REQUEST_USER_SPACE,
          payload: data,
        });
      } catch (er: any) {
        errorHandling(er, dispatch, "update_http_request_fail");
      }
    };

export const updateShowDrawer = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_SHOW_DRAWER,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "update_show_drawer_fail");
  }
};

export const updateSelectedCondition = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_SELECTED_CONDITION,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "update_selected_conditions_fail");
  }
};
export const addErrorsRelatedVariables =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_ERRORS_RELATED_VARIABLES,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "add_errors_related_vars_fail");
    }
  };
export const updateErrorsRelatedVariables =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: ERRORS_RELATED_VARIABLES,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "update_errors_related_vars_fail");
    }
  };

export const deleteErrorsRelatedVariables =
  (nodesId: string[]) => async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_ERRORS_RELATED_VARIABLES,
        payload: nodesId,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "delete_errors_related_vars_fail");
    }
  };

export const getErrorsFields = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: NODES_FIELDS_ERRORS,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "get_errors_fields_fail");
  }
};

export const addArrErrorsFields = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_NODES_FIELDS_ERRORS,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "add_errors_fields_fail");
  }
};

export const deleteArrErrorsFields = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DELETE_NODES_FIELDS_ERRORS,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "delete_errors_fields_fail");
  }
};

export const updateErrorsFields =
  (data: {
    type: string;
    nodeId: string;
    attr: string;
    msg?: string;
    activeMessageSettings?: string;
    typeNode?: string;
  }) =>
    async (dispatch: any) => {
      try {
        dispatch({
          type: UPDATE_NODES_FIELDS_ERRORS,
          payload: data,
        });
      } catch (er: any) {
        errorHandling(er, dispatch, "update_errors_fields_fail");
      }
    };

export const addErrorsFields = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_FIELDS_ERRORS_REPLY,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "add_errors_fields_fail");
  }
};

export const updateErrorsFieldsReply = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_NODES_FIELDS_ERRORS_REPLY,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "update_errors_fields_reply_fail");
  }
};

export const updateFlowOptions = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_FLOW_OPTIONS,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "update_flow_options_fail");
  }
};

export const getOrderedNodes = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_ORDERED_NODES,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "get_ordered_nodes_fail");
  }
};

export const updateOrderedNodes = (data: Object) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_ORDERED_NODES,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "update_ordered_nodes_fail");
  }
};

export const updateOrderedNodesSubFlow =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_ORDERED_NODES_SUB_FLOWS,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "delete_ordered_nodes_fail");
    }
  };

export const startFlowLoading = () => (dispatch: any) => {
  return dispatch({
    type: CUSTOM_LOADING,
  });
};

export const stopFlowLoading = () => (dispatch: any) => {
  return dispatch({
    type: CUSTOM_STOP_LOADING,
  });
};

export const displayErrorModal = () => (dispatch: any) => {
  return dispatch({
    type: DISPLAY_ERROR_MODAL,
  });
};

export const stopErrorModal = () => (dispatch: any) => {
  return dispatch({
    type: STOP_DISPLAY_ERROR_MODAL,
  });
};

export const updateAllVariables = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_ALL_VARIABLES,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "update_all_variables_fail");
  }
};

export const addHttpRequest = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_HTTP_REQUEST_USER_SPACE,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "add_http_request_fail");
  }
};
//subflow
export const getNodesConnectedSubFlow =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: NODES_CONNECTED_SUB_FLOW,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "get_nodes_connected_sub_flow");
    }
  };
export const addNodesConnectedSubFlow =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_NODES_CONNECTED_SUB_FLOW,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "add_nodes_connected_sub_flow");
    }
  };
export const updateNodesConnectedSubFlow =
  (data: any): any =>
    (dispatch: any, getState: any) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({
            type: UPDATE_NODES_CONNECTED_SUB_FLOW,
            payload: data,
          });
          // You can access the updated state if needed
          const updatedState = getState();
          resolve({
            nodesConnectedWithSubFlows:
              updatedState?.flow?.nodesConnectedWithSubFlows,
            customLodaer: updatedState?.flow?.customLodaer,
          });
        } catch (er: any) {
          errorHandling(er, dispatch, "update_nodes_connected_sub_flow");
          reject(er);
        }
      });
    };
export const deleteNodesConnectedSubFlow =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: DELETE_NODES_CONNECTED_SUB_FLOW,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "delete_nodes_connected_sub_flow");
    }
  };

export const displayTemplateModal = () => (dispatch: any) => {
  return dispatch({
    type: DISPLAY_TEMPLATE_MODAL,
  });
};

export const stopTemplateModal = () => (dispatch: any) => {
  return dispatch({
    type: STOP_DISPLAY_TEMPLATE_MODAL,
  });
};
