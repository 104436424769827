import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import UserInfo from "./UserInfo";
import OnlineIndicator from "./OnlineIndicator";
import StaticsView from "./StaticsView";
import ActionsButton from "./ActionsButton";
import { AppContext } from "../../App";
import InternetSpeed from "./InternetSpeed";
import Hidden from "@mui/material/Hidden";
import WalletBalance from "./WalletBalance";
import AdminWrapper from "config/AdminWrapper";
import { UserRoles } from "enums/roles";

interface Props {}

const HeaderContainer = (props: Props) => {
  const [, , minPcMatch] = useContext(AppContext);
  return (
    <Grid
      container
      justifyContent="space-between"
      alignContent={"center"}
      flexWrap="nowrap"
      direction={minPcMatch ? "column" : "row"}
    >
      <Grid
        item
        xs={12}
        container
        lg={2}
        justifyContent="space-between"
        alignContent={"center"}
        gap={1}
        flexWrap="nowrap"
      >
        <Grid item xs={12} lg={"auto"}>
          <UserInfo />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        lg={10}
        justifyContent="flex-end"
        alignContent={"center"}
        gap={1}
      >
        <Grid
          item
          xs={12}
          lg={"auto"}
          sx={{
            mx: minPcMatch ? "1em" : "0",
            my: minPcMatch ? "0.5em" : "0",
          }}
        >
          <OnlineIndicator />
          <Hidden mdUp={true}>
            <InternetSpeed />
          </Hidden>
        </Grid>
        <Grid item xs={12} lg={"auto"} sx={{ mb: minPcMatch ? "1em" : "auto" }}>
          <StaticsView />
        </Grid>
        <AdminWrapper role={[UserRoles.SUPERADMIN, UserRoles.COMPANY_ADMIN]}>
          <Grid item xs={12} lg={"auto"}>
            <WalletBalance />
          </Grid>
        </AdminWrapper>
        <Grid item xs={12} lg={"auto"}>
          <ActionsButton />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeaderContainer;
