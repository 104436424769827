import { createSelector } from "reselect";

export interface Config {
  loading: boolean;
  trigger: boolean;
  navHeight: number;
  duration: number;
  appUpdated: boolean;
  loadingMedia: boolean

}
interface RootState {
  config: Config;
}

// This used to memorize the selector value
export const ConfigSelector = createSelector(
  (state: RootState) => state.config,
  (config: Config) => {
    return {
      loading: config.loading,
      trigger: config.trigger,
      navHeight: config.navHeight,
      duration: config.duration,
      appUpdated: config.appUpdated,
      loadingMedia: config.loadingMedia
    };
  }
);
