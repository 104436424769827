import { createSelector } from "reselect";
import { companyProps, shiftProps } from "./type";

export interface CompanySetting {
  company: companyProps | null;
  shifts: Record<string, shiftProps>;
  orginalShifts: shiftProps[];
  onlineCustomStatus: Array<{
    id: string;
    companyID: number;
    status: string;
    type: 1;
  }>;
  offlineCustomStatus: Array<{
    id: string;
    companyID: number;
    status: string;
    type: 2;
  }>;
}
interface RootState {
  companySetting: CompanySetting;
}

// This used to memorize the selector value
export const companySelector = createSelector(
  (state: RootState) => state.companySetting,
  (companySetting: CompanySetting) => {
    return {
      company: companySetting?.company,
      shifts: companySetting?.shifts,
      onlineCustomStatus: companySetting?.onlineCustomStatus,
      offlineCustomStatus: companySetting?.offlineCustomStatus,
      CustomStatuses: companySetting?.company?.CustomStatuses,
    };
  }
);
