import CustomSwitch from "coreUI/constant/switch/CustomSwitch";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateProfileStatus, updatingProfileStatus } from "redux/auth/actions";
import { authSelector } from "redux/auth/selector";
import { companySelector } from "redux/company/selector";
import { socket } from "socketConfig/socketConnect";

interface Props {}

const OnlineIndicator = (props: Props) => {
  const profileStatus = useSelector(
    (state) => authSelector(state).profileStatus
  );

  const [userVisibility, setUserVisibility] =
    React.useState<boolean>(profileStatus);

  useEffect(() => {
    setUserVisibility((prev) => profileStatus);
  }, [profileStatus]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onlineCustomStatus = useSelector(
    (state) => companySelector(state).onlineCustomStatus,
    shallowEqual
  );
  const offlineCustomStatus = useSelector(
    (state) => companySelector(state).offlineCustomStatus,
    shallowEqual
  );

  const dispatch = useDispatch();
  const handleChangeAgentStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserVisibility(!userVisibility);
    if (offlineCustomStatus?.length > 0 && onlineCustomStatus?.length > 0) {
      setAnchorEl(true ? e.currentTarget : null);
    } else {
      setUserVisibility(!userVisibility);
      dispatch(updatingProfileStatus(!e.target.checked, 0));
    }
  };

  useEffect(() => {
    dispatch(updateProfileStatus());
    return () => {
      socket.off("profileStatus");
    };
  });

  return (
    <CustomSwitch
      checked={userVisibility}
      onChange={handleChangeAgentStatus}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      userVisibility={userVisibility}
      setUserVisibility={setUserVisibility}
    />
  );
};

export default OnlineIndicator;
