import { groupBy } from "lodash";
import { groupProps, groupSetting } from "./type";
import {
  GET_AGENTS_GROUPS,
  GET_GROUPS,
  GET_GROUP_ID,
  CREATE_GROUPS,
  EDIT_GROUPS,
  DELETE_GROUPS,
  GET_SESSION_TYPE_GROUP,
} from "./types";

const initialState: groupSetting = {
  groups: [],
  selectedAgents: [],
  agents: [],
  selectedGroup: { agentIncluded: [], agentNotIncluded: [] },
  groupsValue: {},
  sessionTypes: {},
};

export default function groupReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_GROUPS:
      let groupsValue = {};
      payload.map((item: any) => {
        groupsValue = {
          ...groupsValue,
          [item.groupID]: item.groupName,
        };
        return groupsValue;
      });
      return {
        ...state,
        groups: payload,
        groupsValue,
      };
    case CREATE_GROUPS:
      return {
        ...state,
        groups: [payload, ...state.groups],
      };
    case EDIT_GROUPS: {
      return {
        ...state,
        groups: [...state.groups].map((item: groupProps) =>
          item.groupID === payload?.id
            ? Object.assign({}, item, { groupName: payload.new_name })
            : item
        ),
      };
    }
    case DELETE_GROUPS:
      return {
        ...state,
        groups: state.groups.filter((item: groupProps) => {
          return item.groupID !== payload;
        }),
      };
    case GET_AGENTS_GROUPS:
      return {
        ...state,
        agents: payload,
      };
    case GET_GROUP_ID:
      return {
        ...state,
        selectedGroup: payload,
      };
    case GET_SESSION_TYPE_GROUP:
      let res = groupBy([...payload], ({ engineID }: any) => engineID);
      delete res["undefined"];
      return {
        ...state,
        sessionTypes: res,
      };
    default:
      return state;
  }
}

// case GET_GROUPS_CHANNELS:{
//   const new_channels:any=[];
//   ([...payload]).map((item:any)=>{
//     const obj :any={id :item.channel_id, value:item.permission_id}
//     new_channels.push(obj)
//   })
//   return {
//     ...state,
//     channels: payload,
//     new_channels
//   };
// }
