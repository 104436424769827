export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const GET_COMPANY = "GET_COMPANY";

export const CREATE_SHIFT = "CREATE_SHIFT";
export const DELETE_SHIFT = "DELETE_SHIFT";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const GET_SHIFT = "GET_SHIFT";

export const CREATE_COMPANY_CUSTOM_STATUS = "CREATE_COMPANY_CUSTOM_STATUS";
export const DELETE_COMPANY_CUSTOM_STATUS = "DELETE_COMPANY_CUSTOM_STATUS";
