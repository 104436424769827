import useRole from "hooks/useRole";
import React from "react";
type Props = {
  role: string[];
};

const AdminWrapper: React.FC<Props> = ({ children, role }) => {

  const roleCheck = useRole(role);
  return (
    <>{roleCheck ? children :null}</>
  );
};

export default AdminWrapper;
