import http, { signal } from "../config/httpComman";

class chatService {
  static sendMsgApi(data: any) {
    return http.post("/send", data, signal);
  }
  static createTweet(data: any) {
    return http.post("/send", data, signal);
  }
  static sendMsg(data: any) {
    return http.post("/send/composeMessage", data, signal);
  }

  static postClientInfo(data: any) {
    return http.post("/clientProfile", data, signal);
  }

  static getClientInfo(clientId: number) {
    return http.get(`/clientProfile?id=${clientId}`, signal);
  }

  static async transferSessionGrop(data: any) {
    return await http.post("/group", data, signal);
  }

  static async reactToChat(data: any) {
    return await http.post("/reaction", data, signal);
  }
  static sendTweetApi(data: any) {
    return http.post("/fetch", data, signal);
  }

  static async sendActivity(data: any) {
    return await http.post("/activity", data, signal);
  }
}

export default chatService;
