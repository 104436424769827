import { AppConfig } from "enums/config";
import { RequestStatus } from "enums/request";
import { requests } from "./type";
import {
  ADD_COMMENT,
  ADD_REPLY,
  ADD_TAGS_TO_TICKET,
  ASSIGN_REQUEST,
  CLOSE_REQUEST,
  CREATE_CATEGORY,
  CREATE_REQUEST,
  DELETE_REQ_CATEGORIES,
  DISABLE_EDIT,
  ENABLE_EDIT,
  FILTER,
  GET_REQUEST,
  GET_REQUESTS_ACTIVE,
  GET_REQUESTS_CLOSED,
  GET_REQUESTS_QEUED,
  GET_REQ_CATEGORIES,
  LOADING,
  MARK_SOLUTION,
  REMOVE_TAGS_FROM_TICKET,
  REOPEN_REQUEST,
  RESET_NESTED_REPLY,
  RESET_TICKET,
  SELECT_CATEGORY,
  SENDING,
  SHOW_REPLIES,
  TICKETS_FILTER,
  TRANSFER_REQUEST,
  UNASSIGN_REQUEST,
  UPDATE_REQUEST,
  UPDATE_STATUS_REQUEST,
} from "./types";

const initialState: requests = {
  activeRequests: {},
  closedRequests: {},
  queueRequests: {},
  activeRequestCount: 0,
  queueRequestCount: 0,
  closedRequestCount: 0,
  activeRequestDone: false,
  queueRequestDone: false,
  closedRequestDone: false,
  requestCategories: [],
  selectedCategory: "",
  selectedTickets: null,
  loading: false,
  nestedReplies: null,
  solution: null,
  sending: false,
  editing: {
    active: false,
    categoryID: "",
  },
  filter: false,
  firstTimeFilter: true,
  filterObject: {},
};

export default function requestReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  const getObjectLength = (value: object) => Object.values(value).length;
  switch (type) {
    /* 
    ====================
    request category reducer 
    ====================
    */
    case SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        requestCategories: {
          ...state.requestCategories,
          [payload.id]: payload,
        },
      };
    case GET_REQ_CATEGORIES:
      let reqCategory: any = {};
      payload.forEach((cat: { id: string | number }) => {
        reqCategory[cat.id] = cat;
      });
      return {
        ...state,
        requestCategories: reqCategory,
      };
    case DELETE_REQ_CATEGORIES:
      delete state.requestCategories[payload.categoryId];
      return {
        ...state,
        requestCategories: state.requestCategories,
      };
    /*
    ====================
    request reducer
    ====================
    */
    case CREATE_REQUEST:
      const temp: any = { ...state.queueRequests };
      temp[payload.id] = payload;
      return {
        ...state,
        queueRequests: { ...temp },
      };
    case ASSIGN_REQUEST: {
      // move request from queue to active
      const tempQueue: any = { ...state.queueRequests };
      delete tempQueue[payload.id];
      const tempActive: any = { ...state.activeRequests };
      tempActive[payload.id] = payload;
      return {
        ...state,
        queueRequests: { ...tempQueue },
        activeRequests: { ...tempActive },
        ...(state.selectedTickets?.id === payload.id
          ? { selectedTickets: { ...state.selectedTickets, ...payload } }
          : {}),
      };
    }
    case UNASSIGN_REQUEST: {
      // move request from active to queue
      const tempActive: any = { ...state.activeRequests };
      delete tempActive[payload.id];
      const tempQueue: any = { ...state.queueRequests };
      tempQueue[payload.id] = payload;
      return {
        ...state,
        queueRequests: { ...tempQueue },
        activeRequests: { ...tempActive },
        selectedTickets: null,
      };
    }
    case CLOSE_REQUEST: {
      // move request from active to closed
      const tempActive: any = { ...state.activeRequests };
      delete tempActive[payload.id];
      const tempClosed: any = { ...state.closedRequests };
      tempClosed[payload.id] = payload;
      return {
        ...state,
        activeRequests: { ...tempActive },
        closedRequests: { ...tempClosed },
        selectedTickets: null,
      };
    }
    case REOPEN_REQUEST: {
      // move request from closed to queue
      const tempClosed: any = { ...state.closedRequests };
      delete tempClosed[payload.id];
      const tempQueue: any = { ...state.activeRequests };
      tempQueue[payload.id] = payload;
      return {
        ...state,
        queueRequests: { ...tempQueue },
        closedRequests: { ...tempClosed },
      };
    }
    case UPDATE_STATUS_REQUEST: {
      // update request custom status in any of the 3 categories
      const tempActive: any = { ...state.activeRequests };
      const tempQueue: any = { ...state.queueRequests };
      const tempClosed: any = { ...state.closedRequests };
      if (tempActive[payload.id]) {
        tempActive[payload.id] = payload;
      } else if (tempQueue[payload.id]) {
        tempQueue[payload.id] = payload;
      } else if (tempClosed[payload.id]) {
        tempClosed[payload.id] = payload;
      }
      return {
        ...state,
        activeRequests: { ...tempActive },
        queueRequests: { ...tempQueue },
        closedRequests: { ...tempClosed },
        selectedTickets:
          payload.id === state.selectedTickets?.id
            ? {
                ...state.selectedTickets,
                ...payload,
              }
            : state.selectedTickets,
      };
    }
    case UPDATE_REQUEST: {
      // update request custom status in any of the 3 categories
      const tempActive: any = { ...state.activeRequests };
      const tempQueue: any = { ...state.queueRequests };
      const tempClosed: any = { ...state.closedRequests };
      if (tempActive[payload.id]) {
        tempActive[payload.id] = payload;
      } else if (tempQueue[payload.id]) {
        tempQueue[payload.id] = payload;
      } else if (tempClosed[payload.id]) {
        tempClosed[payload.id] = payload;
      }
      return {
        ...state,
        activeRequests: { ...tempActive },
        queueRequests: { ...tempQueue },
        closedRequests: { ...tempClosed },
        selectedTickets:
          payload.id === state.selectedTickets?.id
            ? {
                ...state.selectedTickets,
                ...payload,
              }
            : state.selectedTickets,
      };
    }
    case TRANSFER_REQUEST: {
      // move request to list that match is status
      const tempActive: any = { ...state.activeRequests };
      const tempQueue: any = { ...state.queueRequests };
      if (payload.status === RequestStatus.Assigned) {
        delete tempQueue[payload.id];
        tempActive[payload.id] = payload;
      }
      if (payload.status === RequestStatus.Unassigned) {
        delete tempActive[payload.id];
        tempQueue[payload.id] = payload;
      }

      return {
        ...state,
        activeRequests: { ...tempActive },
        queueRequests: { ...tempQueue },
      };
    }
    case GET_REQUESTS_ACTIVE: {
      let activeRequests =
        payload.filter && state.firstTimeFilter ? {} : state.activeRequests;
      payload?.data?.forEach(
        (ticket: { status: RequestStatus; id: string }) => {
          activeRequests = {
            ...activeRequests,
            [ticket.id]: ticket,
          };
        }
      );

      let activeLengthNew = getObjectLength(activeRequests);
      let activeLengthOld = getObjectLength(state.activeRequests);

      let lengthActiveCheck =
        activeLengthNew === activeLengthOld &&
        activeLengthOld < AppConfig.GET_SESSIONS_PATCH_SIZE;
      return {
        ...state,
        activeRequests,
        activeRequestCount: activeLengthNew,
        activeRequestDone: lengthActiveCheck
          ? lengthActiveCheck
          : activeLengthNew - activeLengthOld <
            AppConfig.GET_SESSIONS_PATCH_SIZE,
        filter: payload.filter,
      };
    }
    case GET_REQUESTS_QEUED: {
      let queueRequests =
        payload.filter && state.firstTimeFilter ? {} : state.queueRequests;
      payload?.data?.forEach(
        (ticket: { status: RequestStatus; id: string }) => {
          queueRequests = {
            ...queueRequests,
            [ticket.id]: ticket,
          };
        }
      );

      let queuedLengthNew = getObjectLength(queueRequests);
      let qeuedLengthOld = getObjectLength(state.queueRequests);

      let lengthActiveCheck =
        queuedLengthNew === qeuedLengthOld &&
        qeuedLengthOld < AppConfig.GET_SESSIONS_PATCH_SIZE;
      return {
        ...state,
        queueRequests,
        queueRequestCount: queuedLengthNew,
        queueRequestDone: lengthActiveCheck
          ? lengthActiveCheck
          : queuedLengthNew - qeuedLengthOld <
            AppConfig.GET_SESSIONS_PATCH_SIZE,
        filter: payload.filter,
      };
    }
    case GET_REQUESTS_CLOSED: {
      let closedRequests =
        payload.filter && state.firstTimeFilter ? {} : state.closedRequests;
      payload?.data?.forEach(
        (ticket: { status: RequestStatus; id: string }) => {
          closedRequests = {
            ...closedRequests,
            [ticket.id]: ticket,
          };
        }
      );

      let closedLengthNew = getObjectLength(closedRequests);
      let closedLengthOld = getObjectLength(state.closedRequests);

      let lengthActiveCheck =
        closedLengthNew === closedLengthOld &&
        closedLengthOld < AppConfig.GET_SESSIONS_PATCH_SIZE;
      return {
        ...state,
        closedRequests,
        closedRequestCount: closedLengthNew,
        closedRequestDone: lengthActiveCheck
          ? lengthActiveCheck
          : closedLengthNew - closedLengthOld <
            AppConfig.GET_SESSIONS_PATCH_SIZE,
        filter: payload.filter,
      };
    }
    case FILTER: {
      return {
        ...state,
        filter: payload,
        selectedTickets: null,
        firstTimeFilter: false,
      };
    }
    case GET_REQUEST:
      return {
        ...state,
        selectedTickets: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case RESET_TICKET:
      return {
        ...state,
        selectedTickets: null,
      };
    case ADD_COMMENT:
      return {
        ...state,
        selectedTickets: {
          ...state.selectedTickets,
          comments: [...(state?.selectedTickets?.comments ?? []), payload],
        },
      };
    case ADD_REPLY: {
      let targetReply: any = state?.selectedTickets?.replies ?? {};
      // check if the new reply is nested
      let newReplies = null;
      let totalReplies = 0;
      if (targetReply[`${payload?.replyID}`]) {
        newReplies = [
          ...(targetReply[`${payload?.replyID}`]?.replies ?? []),
          payload,
        ];
        totalReplies = targetReply[`${payload?.replyID}`]?.RepliesNumber;
      }
      return {
        ...state,
        nestedReplies: state.nestedReplies && [
          {
            ...state.nestedReplies[0],
            replies: [...(state?.nestedReplies[0]?.replies ?? []), payload],
          },
        ],
        selectedTickets: {
          ...state.selectedTickets,
          ...(targetReply[`${payload?.replyID}`]
            ? {}
            : {
                totalRepliesNumber:
                  (state?.selectedTickets?.totalRepliesNumber ?? 0) + 1,
              }),
          replies: {
            ...(state?.selectedTickets?.replies ?? {}),
            [payload.id]: payload,
            ...(newReplies
              ? {
                  [payload.replyID]: {
                    ...targetReply[`${payload?.replyID}`],
                    replies: newReplies,
                    RepliesNumber: totalReplies + 1,
                  },
                }
              : {}),
          },
        },
      };
    }
    case SENDING: {
      return {
        ...state,
        sending: payload,
      };
    }
    case SHOW_REPLIES:
      return {
        ...state,
        nestedReplies: [payload],
      };
    case RESET_NESTED_REPLY:
      return {
        ...state,
        nestedReplies: null,
      };
    case MARK_SOLUTION: {
      return {
        ...state,
        solution: payload,
      };
    }
    case ENABLE_EDIT: {
      return {
        ...state,
        editing: {
          active: true,
          categoryID: state.requestCategories?.[payload] ? payload : "",
        },
      };
    }
    case DISABLE_EDIT: {
      return {
        ...state,
        editing: {
          active: false,
          categoryID: "",
        },
      };
    }
    case ADD_TAGS_TO_TICKET: {
      let tags = payload ? payload : [];
      let finalTags = [...(state.selectedTickets?.tags ?? []), ...tags];
      return {
        ...state,
        selectedTickets: {
          ...state.selectedTickets,
          tags: finalTags,
          activeRequests: {
            ...state.activeRequests,
            [state?.selectedTickets?.id ?? ""]: {
              ...state.activeRequests[state?.selectedTickets?.id ?? ""],
              tags: finalTags,
            },
          },
        },
      };
    }
    case REMOVE_TAGS_FROM_TICKET: {
      let finalTags = (state.selectedTickets?.tags ?? []).filter(
        (tag: { id: number }) => tag.id !== payload
      );
      return {
        ...state,
        selectedTickets: {
          ...state.selectedTickets,
          tags: finalTags,
          activeRequests: {
            ...state.activeRequests,
            [payload.ticketID]: {
              ...state.activeRequests[payload.ticketID],
              tags: finalTags,
            },
          },
        },
      };
    }
    case TICKETS_FILTER: {
      return {
        ...state,
        filterObject: payload,
      };
    }
    default:
      return state;
  }
}
