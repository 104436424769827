import { channelsProps, engineProps } from "./type";

export const updateChannels = ({
  engines,
  channels,
}: {
  engines: engineProps[];
  channels: channelsProps[];
}) => {
  if (engines && engines.length > 0) {
    engines.forEach((engine: engineProps) => {
      const res: channelsProps | undefined = channels.find(
        (item: channelsProps) => item.engineID === engine.ID
      );
      if (res && res !== undefined) {
        res.icon = engine.icon;
      }
    });
  }
  return channels;
};
