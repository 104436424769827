import { filter, isEmpty } from "lodash";
import { CampaignData, operationCampaignMs } from "./selector";
import {
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  RESET_CUREENT_CAMPAIGN,
  FILE_LINK,
  GET_CAMPAIGN,
  RESEND_MSG_DATA,
  RESET_CAMPAIGN,
  SELECTED_CAMPAIGN,
  UPDATE_CAMPAIGN,
  OPERATION,
  FILTER,
} from "./types";

interface IntialState {
  campaigns: object[];
  TotalCampa: number;
  NextPageToken: string;
  selectedCampaign: object | null;
  currentCampaign: CampaignData | null;
  resendMsgData: null | object;
  link?: string;
  operation?: operationCampaignMs;
  filter: boolean;
}

const initialState: IntialState = {
  campaigns: [],
  TotalCampa: 0,
  NextPageToken: "",
  selectedCampaign: null,
  currentCampaign: null,
  resendMsgData: null,
  filter: false,
};

export default function campaignReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case OPERATION:
      return {
        ...state,
        operation: payload || "",
      };
    case FILTER:
      return {
        ...state,
        filter: payload,
      };
    case CREATE_CAMPAIGN:
      return {
        ...state,
        campaigns: [payload, ...state.campaigns],
      };
    case GET_CAMPAIGN: {
      let campaigns = payload?.Campaigns ?? [];
      return {
        ...state,
        campaigns: state.filter
          ? campaigns
          : state.NextPageToken !== payload.NextPageToken ||
            payload.NextPageToken === ""
          ? [...state.campaigns, ...campaigns]
          : state.campaigns,
        ...payload,
      };
    }
    case SELECTED_CAMPAIGN: {
      let selectedCamp: {
        Messages?: object[];
        CampaignID?: string;
        NextPageToken?: string;
      } | null = {
        ...state?.selectedCampaign,
      };

      if (
        selectedCamp?.Messages &&
        selectedCamp.CampaignID === payload?.CampaignID
      ) {
        selectedCamp.Messages = [...selectedCamp.Messages, ...payload.Messages];
        selectedCamp.NextPageToken = payload.NextPageToken;
      }

      return {
        ...state,
        selectedCampaign: !isEmpty(selectedCamp) ? selectedCamp : payload,
      };
    }
    case DELETE_CAMPAIGN: {
      let newCapaign = state.campaigns.filter(
        (item: any) => item.ID !== payload.ID
      );
      return {
        ...state,
        campaigns: newCapaign,
        selectedCampaign: {},
        currentCampaign: null,
      };
    }
    case UPDATE_CAMPAIGN: {
      let campaignState: any = state.campaigns;
      let newCampaign = campaignState
        .map((item: any) => item.ID)
        .indexOf(payload.ID);
      let updatedCampaign = {
        ...payload,
        ChannelID: campaignState[newCampaign]?.ChannelID,
      };
      campaignState.splice(newCampaign, 1, updatedCampaign);
      return {
        ...state,
        campaigns: campaignState,
      };
    }
    case RESEND_MSG_DATA: {
      return {
        ...state,
        resendMsgData: payload,
      };
    }
    case FILE_LINK:
      return {
        ...state,
        link: payload,
      };
    case RESET_CAMPAIGN:
      return {
        ...state,
        NextPageToken: "",
        selectedCampaign: null,
        resendMsgData: null,
        currentCampaign: null,
        campaigns: [],
        operation: "",
        filter: false,
      };
    case RESET_CUREENT_CAMPAIGN:
      let res = filter(
        [...state.campaigns],
        (item: any) => item?.ID === payload
      );
      return {
        ...state,
        currentCampaign: res?.length > 0 ? res[0] : null,
        selectedCampaign: null,
      };
    default:
      return state;
  }
}
