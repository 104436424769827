import { Community } from "./selector";
import {
  CHANGE_MEMBER_ROLE_CHANNEL,
  COMMUNITY_MESSAGES_COMPLETE,
  COMMUNITY_NEW_MESSAGE_NOTIFICATION,
  COMMUNITY_OFFSET,
  CREATE_COMMUNITY_CHANNEL,
  DELETE_COMMUNITY_CHANNEL,
  GET_COMMUNITY_CHANNELS,
  GET_COMMUNITY_DIRECTS,
  GET_COMMUNITY_GROUPS,
  GET_COMMUNITY_MEMBERS,
  GET_COMMUNITY_MESSAGES,
  GET_MSG_RECIPIENTS,
  INVITE_MEMBER_CHANNEL,
  REMOVE_MEMBER_CHANNEL,
  RESET_NEW_MESSAGE_NOTIFICATION,
  SEARCH_COMMUNITY,
  SELECT_COMMUNITY,
  SEND_COMMUNITY_MESSAGE,
  SEND_COMMUNITY_MESSAGE_CONFIRMATION,
  SEND_MSG_ACKNOWLEDGMENT,
  UPDATE_COMMUNITY_CHANNELS,
  UPDATE_MEMBER_VISIBILITY,
} from "./types";

const initialState: Community = {
  channels: {},
  groups: {},
  directs: {},
  searchResults: {
    channels: null,
    groups: null,
    directs: null,
  },
  selected: null,
  communityMembers: {
    members: [],
    channelID: "",
  },
  selectedCommunityMessages: null,
  getAnotherPatch: true,
  communityMessagesOffset: 0,
  messageRecipients: {},
  newMessageNotification: false,
  newMessageNotificationData: {},
};

export default function communityReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case SELECT_COMMUNITY:
      return {
        ...state,
        selected: {
          ...(state.channels[payload]
            ? {
                ...state.channels[payload],
                type: "channel",
                selectedId: payload,
              }
            : state.groups[payload]
            ? { ...state.groups[payload], type: "group", selectedId: payload }
            : state.directs[payload]
            ? { ...state.directs[payload], type: "direct", selectedId: payload }
            : null),
        },
        selectedCommunityMessages: null,
        getAnotherPatch: true,
        communityMessagesOffset: 0,
        newMessageNotification: false,
        newMessageNotificationData: {},
      };

    case SEARCH_COMMUNITY: {
      if (payload === "") {
        return {
          ...state,
          searchResults: {
            channels: null,
            groups: null,
            directs: null,
          },
        };
      }

      const directsFilter = Object.values(state.directs).filter(
        (direct: any) =>
          direct?.firstName?.toLowerCase()?.includes(payload?.toLowerCase()) ||
          direct?.lastName?.toLowerCase()?.includes(payload?.toLowerCase())
      );
      const channels = Object.values(state.channels).filter(
        (channel: any) =>
          channel.name.toLowerCase().includes(payload.toLowerCase()) ||
          channel.description.toLowerCase().includes(payload.toLowerCase())
      );
      const groups = Object.values(state.groups).filter(
        (group: any) =>
          group.groupName.toLowerCase().includes(payload.toLowerCase()) ||
          group.groupID.toLowerCase().includes(payload.toLowerCase())
      );

      return {
        ...state,
        searchResults: {
          channels: channels.reduce((acc: any, channel: any) => {
            acc[channel.id] = channel;
            return acc;
          }, {}),
          groups: groups.reduce((acc: any, group: any) => {
            acc[group.groupID] = group;
            return acc;
          }, {}),
          directs: directsFilter.reduce((acc: any, direct: any) => {
            acc[direct.ReceiverId] = direct;
            return acc;
          }, {}),
        },
      };
    }
    case GET_COMMUNITY_CHANNELS:
      const channels = payload.Channels.reduce((acc: any, channel: any) => {
        acc[channel.id] = channel;
        return acc;
      }, {});
      return {
        ...state,
        channels,
      };
    case CREATE_COMMUNITY_CHANNEL:
      return {
        ...state,
        channels: {
          ...state.channels,
          [payload.id]: payload,
        },
      };
    // TODO: convert channels to object with id as key
    case UPDATE_COMMUNITY_CHANNELS:
      return {
        ...state,
        channels: payload,
      };
    case DELETE_COMMUNITY_CHANNEL:
      return {
        ...state,
        channels: payload,
      };
    case GET_COMMUNITY_GROUPS:
      const groups = payload.groups.reduce((acc: any, group: any) => {
        acc[group.groupID] = group;
        return acc;
      }, {});
      return {
        ...state,
        groups,
      };
    case GET_COMMUNITY_DIRECTS:
      const directs = payload.reduce((acc: any, direct: any) => {
        acc[direct.ReceiverId] = direct;
        return acc;
      }, {});
      return {
        ...state,
        directs,
      };
    case GET_COMMUNITY_MEMBERS:
      return {
        ...state,
        communityMembers: payload,
      };
    // TODO : access members property in channel object and update it by adding new member
    case INVITE_MEMBER_CHANNEL:
      return {
        ...state,
        communityMembers: {
          ...state.communityMembers,
          members: [...state.communityMembers.members, payload],
        },
      };
    // TODO : access members property in channel object and update it by removing a member
    case REMOVE_MEMBER_CHANNEL:
      return {
        ...state,
        communityMembers: {
          ...state.communityMembers,
          members: state.communityMembers.members.filter(
            (member: any) => member.agentId !== payload.agentId
          ),
        },
      };
    case CHANGE_MEMBER_ROLE_CHANNEL:
      const { agentId, UpdatedRole } = payload;
      const { members } = state.communityMembers;
      const index = members.findIndex(
        (member: any) => member.agentId === agentId
      );
      members[index].agentRole = UpdatedRole;
      return {
        ...state,
        communityMembers: {
          ...state.communityMembers,
          members: [...members],
        },
      };
    case UPDATE_MEMBER_VISIBILITY:
      return {
        ...state,
        communityMembers: {
          ...state.communityMembers,
          members: state.communityMembers.members.map((member: any) => {
            if (member.agentId === payload.agentID) {
              return {
                ...member,
                communityConnected: payload.connected,
              };
            }
            return member;
          }),
        },
        directs: {
          ...state.directs,
          [payload?.agentID]: {
            ...state.directs[payload?.agentID],
            communityConnected: payload?.connected,
          },
        },
      };
    case SEND_COMMUNITY_MESSAGE:
      return {
        ...state,
        selectedCommunityMessages: {
          ...state.selectedCommunityMessages,
          messages: [
            ...(state.selectedCommunityMessages?.messages || []),
            { ...payload, pending: true },
          ],
        },
      };
    case SEND_COMMUNITY_MESSAGE_CONFIRMATION:
      const idMapping = {
        id: "channelID",
        groupID: "groupID",
        ReceiverId: "receiverID",
      };

      for (const [selectedKey, payloadKey] of Object.entries(idMapping)) {
        const id = payload[payloadKey];
        if (!id) continue;
        if (id && state.selected?.[selectedKey] === id) {
          return {
            ...state,
            selectedCommunityMessages: {
              ...state.selectedCommunityMessages,
              messages: state.selectedCommunityMessages?.messages.map(
                (message: any) => {
                  if (message.reqID === payload.reqID) {
                    return {
                      ...message,
                      ...payload,
                      pending: false,
                    };
                  }
                  return message;
                }
              ),
            },
          };
        }
      }
      return {
        ...state,
      };
    case COMMUNITY_NEW_MESSAGE_NOTIFICATION: {
      // check if the payload contain channelID or groupID or receiverID
      // if channelID then check if the channel is selected
      // if groupID then check if the group is selected
      // if receiverID then check if the receiver is selected
      // if any of the above is true then update the selectedCommunityMessages
      // else update the unreadMessages count
      const idMapping = {
        id: "channelID",
        groupID: "groupID",
        ReceiverId: "senderID",
      };

      for (const [selectedKey, payloadKey] of Object.entries(idMapping)) {
        const id = payload[payloadKey];
        if (!id) continue;
        if (id && state.selected?.[selectedKey] === id) {
          return {
            ...state,
            selectedCommunityMessages: {
              ...state.selectedCommunityMessages,
              messages: [
                ...(state.selectedCommunityMessages?.messages || []),
                payload,
              ],
            },
          };
        } else {
          return {
            ...state,
            newMessageNotification: true,
            newMessageNotificationData: payload,
            ...(state.channels[id]
              ? {
                  channels: {
                    ...state.channels,
                    [id]: {
                      ...state.channels[id],
                      unreadMessages:
                        state.channels[id].unreadMessages + 1 || 1,
                    },
                  },
                }
              : state.groups[id]
              ? {
                  groups: {
                    ...state.groups,
                    [id]: {
                      ...state.groups[id],
                      unreadMessages: state.groups[id].unreadMessages + 1 || 1,
                    },
                  },
                }
              : state.directs[id]
              ? {
                  directs: {
                    ...state.directs,
                    [id]: {
                      ...state.directs[id],
                      unreadMessages: state.directs[id].unreadMessages + 1 || 1,
                    },
                  },
                }
              : {}),
          };
        }
      }
      return {
        ...state,
      };
    }
    case RESET_NEW_MESSAGE_NOTIFICATION:
      return {
        ...state,
        newMessageNotification: false,
        newMessageNotificationData: {},
      };
    case GET_COMMUNITY_MESSAGES:
      if (
        payload.channelID === state.selected?.selectedId ||
        payload.groupID === state.selected?.selectedId ||
        payload.receiverID === state.selected?.selectedId
      ) {
        return {
          ...state,
          selectedCommunityMessages: {
            ...state.selectedCommunityMessages,
            messages: [
              ...payload.messages,
              ...(state.selectedCommunityMessages?.messages || []),
            ],
          },
        };
      } else {
        return {
          ...state,
          selectedCommunityMessages: payload,
        };
      }
    case COMMUNITY_MESSAGES_COMPLETE:
      return {
        ...state,
        getAnotherPatch: payload,
      };
    case COMMUNITY_OFFSET: {
      return {
        ...state,
        communityMessagesOffset: payload,
      };
    }
    case GET_MSG_RECIPIENTS: {
      return {
        ...state,
        messageRecipients: payload,
      };
    }
    case SEND_MSG_ACKNOWLEDGMENT: {
      return {
        ...state,
        ...(state.channels[payload]
          ? {
              channels: {
                ...state.channels,
                [payload]: {
                  ...state.channels[payload],
                  unreadMessages: 0,
                },
              },
            }
          : state.groups[payload]
          ? {
              groups: {
                ...state.groups,
                [payload]: {
                  ...state.groups[payload],
                  unreadMessages: 0,
                },
              },
            }
          : state.directs[payload]
          ? {
              directs: {
                ...state.directs,
                [payload]: {
                  ...state.directs[payload],
                  unreadMessages: 0,
                },
              },
            }
          : {}),
      };
    }
    default:
      return state;
  }
}
