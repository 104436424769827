/* 
====================
request category  
====================
*/
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_REQ_CATEGORIES = "GET_REQ_CATEGORIES";
export const DELETE_REQ_CATEGORIES = "DELETE_REQ_CATEGORIES";
/* 
====================
request   
====================
*/
export const CREATE_REQUEST = "CREATE_REQUEST";
export const GET_REQUESTS_ACTIVE = "GET_REQUEST_ACTIVE";
export const GET_REQUESTS_QEUED = "GET_REQUEST_QEUED";
export const GET_REQUESTS_CLOSED = "GET_REQUEST_CLOSED";
export const GET_REQUEST = "GET_REQUEST";
export const ASSIGN_REQUEST = "ASSIGN_REQUEST";
export const UNASSIGN_REQUEST = "UNASSIGN_REQUEST";
export const CLOSE_REQUEST = "CLOSE_REQUEST";
export const REOPEN_REQUEST = "REOPEN_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const TRANSFER_REQUEST = "TRANSFER_REQUEST";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_REPLY = "ADD_REPLY";
export const LOADING = "LOADING";
export const RESET_TICKET = "RESET_TICKET";
export const SHOW_REPLIES = "SHOW_REPLIES";
export const MARK_SOLUTION = "MARK_SOLUTION";
export const RESET_NESTED_REPLY = "RESET_NESTED_REPLY";
export const SENDING = "SENDING";
export const FILTER = "FILTER";
export const ADD_TAGS_TO_TICKET = "ADD_TAGS_TO_TICKET";
export const REMOVE_TAGS_FROM_TICKET = "REMOVE_TAGS_FROM_TICKET";
export const TICKETS_FILTER = "TICKETS_FILTER";

/* 
====================
edit request   
====================
*/
export const ENABLE_EDIT = "ENABLE_EDIT";
export const DISABLE_EDIT = "DISABLE_EDIT";
