import { UserSeting } from "./selector";
import { UPDATE_ACCOUNT, GET_ACCOUNT } from "./types";

const initialState: UserSeting = {
  account: null,
};

export default function accountReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ACCOUNT: {
      return {
        ...state,
        account: payload,
      };
    }
    case GET_ACCOUNT:
      return {
        ...state,
        account: payload.data,
      };
    default:
      return state;
  }
}
