import { kpiSetting, genKpiProps } from "./type";

import {
  GET_KPIS,
  DELETE_KPI,
  CREATE_KPI,
  GET_GENERATED_KPIS,
  UPDATE_KPIS,
} from "./types";

const initialState: kpiSetting = {
  kpis: [],
  agentFilter: [],
  genratedKpi: [],
  agentTrace:{}
};

export const agentTraceFun = (kpi: any,agentTrace:any) => {
  // we need this to catch all the selected agents
  // for specific card id for different kpis
  if (agentTrace[kpi?.Name]) {
    agentTrace = {
      ...agentTrace,
      [kpi.Name]: {
        ...agentTrace[kpi.Name],
        [kpi.ID]: kpi.AgentIDs,
      },
    };
  } else {
    agentTrace = {
      ...agentTrace,
      [kpi.Name]: {
        [kpi.ID]: kpi.AgentIDs,
      },
    };
  }

  return agentTrace
};

export default function kpiReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_KPIS:
      return {
        ...state,
        kpis: payload.map((kpi: any) => {
          return { id: kpi.Name, value: kpi.DataType, text: kpi.Name };
        }),
      };
    case CREATE_KPI:
        let agentTrace = agentTraceFun(payload,state.agentTrace)
        let selectedAgent: any = state.agentFilter;
        if (selectedAgent[payload?.KPIid]) {
            let newData = new Set([
              ...selectedAgent[payload.KPIid],
              ...(payload?.AgentIDs ?? []),
            ]);
            selectedAgent[payload.KPIid] = [...newData];
          } else {
            selectedAgent[payload.KPIid] = [...(payload?.AgentIDs ?? [])];
          }

      return {
        ...state,
        genratedKpi: [payload, ...state.genratedKpi],
        agentTrace:agentTrace,
        agentFilter:selectedAgent
      };

    case GET_GENERATED_KPIS: {
      let selectedAgent: any = {};
      let agentTrace: any = {};
      let res=[...payload]
      res.sort((a:any,b:any)=>{
        return  a.CreatedAt < b.CreatedAt ? 1 : a.CreatedAt > b.CreatedAt ? -1 : 0 
      });
      res?.map((item: any) => {
        // we need this to cach all the selected agents in
        // all the kpis
        if (selectedAgent[item?.KPIid]) {
          let newData = new Set([
            ...selectedAgent[item.KPIid],
            ...(item?.AgentIDs ?? []),
          ]);
          selectedAgent[item.KPIid] = [...newData];
        } else {
          selectedAgent[item.KPIid] = [...(item?.AgentIDs ?? [])];
        }

         agentTrace = agentTraceFun(item,agentTrace)
         return item

      });
      return {
        ...state,
        genratedKpi: res,
        agentFilter: selectedAgent,
        agentTrace:agentTrace
      };
    }

    case UPDATE_KPIS: {
        let agentTrace = state.agentTrace
        let id = payload.id
        delete payload.id

        let generatedKpi = [...state.genratedKpi].map((item: genKpiProps) =>{
        if(item.ID===id) {
            if(payload.AgentIDs) {
                agentTrace = agentTraceFun({...item,AgentIDs:payload.AgentIDs},agentTrace)
            }
            return Object.assign({}, item, { ID: id ,...payload})
        }
        return item
      })

      let selectedAgent: any = state.agentFilter;
      if (selectedAgent[payload?.KPIid]) {
          let newData = new Set([
            ...selectedAgent[payload.KPIid],
            ...(payload?.AgentIDs ?? []),
          ]);
          selectedAgent[payload.KPIid] = [...newData];
        } else {
          selectedAgent[payload.KPIid] = [...(payload?.AgentIDs ?? [])];
        }

      return {
        ...state,
        genratedKpi: generatedKpi,
        agentTrace,
        agentFilter:selectedAgent
      };
    }
    case DELETE_KPI: {
        let agentTrace:any = state.agentTrace
        let agentFilter:any = state.agentFilter

        let genratedKpi = state.genratedKpi.filter((item: any) => {
            if(item.ID === payload){

                // remove agent id in filter
                delete agentFilter[item.KPIid]

                // remove agent trace
               delete agentTrace[item.Name][item.ID]
            }

            // remove agent trace
            if(Object.keys(agentTrace[item.Name]).length === 0){
                delete agentTrace[item.Name]
            }
            return item.ID !== payload;
          })


      return {
        ...state,
        genratedKpi: genratedKpi,
        agentTrace,
        agentFilter
      };
    }
    default:
      return state;
  }
}
