export const LOGIN_STATUS = "LOGIN_STATUS";
export const GET_PROFILE = "GET_PROFILE";
export const ADD_TOKEN = "ADD_TOKEN";
export const USER_LOGOUT = "USER_LOGOUT";
export const DISABLE_CURRENT_TAB_SESSION = "DISABLE_CURRENT_TAB_SESSION";
export const UPDATE_PROFILE_STATUE = "UPDATE_PROFILE_STATUE";
export const UPDATE_PROFILE_PREFERENCES = "UPDATE_PROFILE_PREFERENCES";
export const UPDATE_PROFILE_STATUE_COMMUNITY =
  "UPDATE_PROFILE_STATUE_COMMUNITY";
export const USER_SIGNUP = "USER_SIGNUP";
export const USER_VERIFY = "USER_VERIFY";
export const RESET_REGIST = "RESET_REGIST";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const OTHER_LOGOUT = "OTHER_LOGOUT";
export const LOADING_LOGIN="LOADING_LOGIN"
