import { keys, map, trimStart } from "lodash";

const dataTime = [
  "waiting_time",
  "service_time",
  "response_time",
  "total_online_time",
];
// const dataDate = ["created_date", "taken_date", "updated_date", "ended_date"];

export const renderValue = (label: string, value: string | number) => {
  // the next if has been moved to AG-Grid file
  // under function formatDataToSeconds and formatDataToTime
  /* if (typeof value === "number" && dataTime.includes(label)) {
    if (value < 0) {
      return "_";
    }
    return process.env.REACT_APP_ENV === "productionTA"
      ? secondsToHMS(value)
      : timeConvert(value, true, false, true);
  } */
  /* if (typeof value==="string" && moment(value, moment.ISO_8601, true).isValid()) {
        return moment(value).format("YYYY-MM-DD HH:mm:ss");
  } */
  return trimStart(value as string);
};

export const processDataTable = (data: Record<string, any>) => {
  let headCells: {
    id: string;
    field: string;
    enableRowGroup: boolean;
    filter: boolean;
  }[] = [];
  let dataTable = [];
  if (data && data?.cardContent) {
    keys(data?.cardContent[0]).forEach((item, index) => {
      headCells.push({
        id: item,
        field: item,
        enableRowGroup: true,
        filter: true,
      });
    });
    dataTable = data?.cardContent;
  }

  dataTable.map((item: any) => {
    return map(
      headCells,
      (cell: {
        id: string;
        field: string;
        enableRowGroup: boolean;
        filter: boolean;
      }) => (item[cell.id] = renderValue(cell.id, item[cell.id]) || "_")
    );
  });
  return { headCells, dataTable };
};
