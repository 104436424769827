export const CREATE_SURVEY = "CREATE_SURVEY";
export const GET_SURVEY = "GET_SURVEY";
export const SELECTED_SURVEY = "SELECTED_SURVEY";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const RESEND_MSG = "RESEND_MSG";
export const EXPORT_FILE = "EXPORT_FILE";
export const RESEND_MSG_DATA = "RESEND_MSG_DATA";
export const FILE_LINK = "FILE_LINK";
export const RESET_SURVEY = "RESET_SURVEY";
export const RESET_CUREENT_SURVEY = "RESET_CUREENT_SURVEY";
export const OPERATION = "OPERATION";
export const FILTER = "FILTER";
