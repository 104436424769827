import { AxiosResponse } from "axios";
import { VariantType } from "notistack";
import mediaService from "services/media.service";
import { errorHandling } from "utils/errorHandling";
import {
  APP_UPDATED,
  LOADING,
  LOADING_MEDIA,
  REMOVE_ALERT,
  SET_NAV_HEIGHT,
  STOP_LOADING,
  TRIGGER_ALERT,
} from "./types";

export const triggerAlert =
  (msg: string, variant: VariantType) => (dispatch: any) => {
    return dispatch({
      type: TRIGGER_ALERT,
      payload: { msg, variant },
    });
  };

export const removeAlert = (duration?: number) => (dispatch: any) => {
  return dispatch({
    type: REMOVE_ALERT,
    payload: duration,
  });
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const setNavHeight = (height: number) => (dispatch: any) => {
  return dispatch({
    type: SET_NAV_HEIGHT,
    payload: height,
  });
};
export const setAppUpdated = (updated: boolean) => (dispatch: any) => {
  return dispatch({
    type: APP_UPDATED,
    payload: updated,
  });
};
export const loadingUploadMedia = (loading: boolean) => (dispatch: any) => {
  return dispatch({
    type: LOADING_MEDIA,
    payload: loading,
  });
};
export const uploadMedia =
  (
    file: File,
    isCallingLoader: boolean = true,
    returnObject: boolean = false
  ) =>
  async (dispatch: any) => {
    isCallingLoader && dispatch(loadingUploadMedia(true));
    try {
      const mediaData = new FormData();
      mediaData.append("media", file);

      const mediaResponse: AxiosResponse = await mediaService.uploadMedia(
        mediaData
      );
      isCallingLoader && dispatch(loadingUploadMedia(false));
      if (returnObject) return mediaResponse?.data || {};
      return mediaResponse?.data?.url || "";
    } catch (er: any) {
      errorHandling(er, dispatch, "massage_failed");
      dispatch(loadingUploadMedia(false));
    }
  };
