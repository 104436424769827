import { notes } from "./type";
import { ADD_NOTE, GET_NOTES, UPDATE_NOTE } from "./types";

const initialState: notes = {
  currentUserId: 0,
  notes: [],
};

export default function notesReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTES:
      return {
        ...state,
        notes: payload,
      };
    case ADD_NOTE:
      return {
        ...state,
        notes: [...state.notes, { ...payload, loading: true }],
      };
    case UPDATE_NOTE:
      return {
        ...state,
        notes: state.notes.map((note) => {
          if (note.noteKey === payload.noteKey) {
            return { ...note, ...payload };
          }
          return note;
        }
        ),
      };
    default:
      return state;
  }
}
