import React, { useContext } from "react";
import Box from "@mui/material/Box";
import { AppContext } from "../../../App";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { CssBaseline, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ElevationScroll from "../ElevationScroll";
import HeaderContainer from "../../header/HeaderContainer";
import { AvatarContext } from "../left-drawer/Avatar";
import CloseIcon from "@mui/icons-material/Close";
interface Props {}

const RightSectionDrawer: React.FC<Props> = (props) => {
  const [, theme, , , , mobileMatch] = useContext(AppContext);

  const [showDrawer, setShowDrawer] = useContext(AvatarContext);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const list = () => (
    <Box>
      <HeaderContainer />
    </Box>
  );

  const checkTabScreen = useMediaQuery("(min-width:700px)");
  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <SwipeableDrawer
          anchor={"right"}
          open={showDrawer}
          variant="temporary"
          onClose={() => setShowDrawer(false)}
          onOpen={() => setShowDrawer(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          sx={{
            "& .MuiDrawer-paper": {
              width: mobileMatch ? (checkTabScreen ? "50%" : "100%") : "42%",
              bgcolor: theme.palette.primary.main,
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowDrawer(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.general.white,
            }}
          >
            <CloseIcon />
          </IconButton>
          {list()}
        </SwipeableDrawer>
      </ElevationScroll>
    </>
  );
};

export default RightSectionDrawer;
