export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const SELECTED_CAMPAIGN = "SELECTED_CAMPAIGN";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const RESEND_MSG = "RESEND_MSG";
export const EXPORT_FILE = "EXPORT_FILE";
export const RESEND_MSG_DATA = 'RESEND_MSG_DATA'
export const FILE_LINK = "FILE_LINK"
export const RESET_CAMPAIGN = "RESET_CAMPAIGN"
export const RESET_CUREENT_CAMPAIGN="RESET_CUREENT_CAMPAIGN"
export const OPERATION="OPERATION"
export const FILTER="FILTER"
