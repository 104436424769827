import React from "react";
import MenuList from "@mui/material/MenuList";
import { MenuData } from "./type";
import NormalList from "./NormalList";
import DropDownList from "./DropDownList";

interface Props {}

const HiddenList: React.FC<Props & MenuData> = ({
  icons = [],
  handleSetPageUrl,
}) => {
  return (
    <MenuList>
      {icons.map((item) =>
        item.kind === "normal" ? (
          <NormalList
            key={item.id}
            item={item}
            handleSetPageUrl={handleSetPageUrl}
          />
        ) : (
          <DropDownList
            key={item.id}
            item={item}
            handleSetPageUrl={handleSetPageUrl}
          />
        )
      )}
    </MenuList>
  );
};

export default HiddenList;
