import { findKey } from "lodash";
import { tagsProps } from "./type";

export const findTargetIndex = (
  tags: { [key: string]: tagsProps[] },
  id: number | string
) => {
//   console.log({ tags, id });
  return (
    findKey(tags, (item: tagsProps[]) => {
      return item.find((i) => i.id === Number(id));
    }) || ""
  );
};
