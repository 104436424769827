import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { LicenseManager } from "ag-grid-enterprise";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { APP_UPDATED } from "redux/appSetting/types";
import App from "./App";
import "./i18n/i18n";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (process.env.REACT_APP_ENV !== "cppa") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [
      new BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

LicenseManager.setLicenseKey(
  `Using_this_AG_Grid_Enterprise_key_( AG-041446 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Experia )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Experia )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Experia )_need_to_be_licensed___( Experia )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23 April 2024 )____[v2]_MTcxMzgyNjgwMDAwMA==a8a1dd927a42b60ec71d1afb9a2c1115`
);

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event: any) => {
        store.dispatch({
          type: APP_UPDATED,
          payload: event.target.state === "activated",
        });
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
