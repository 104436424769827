import { PlanSubscribe } from "redux/subscribe/type";
import http, { signal } from "../config/httpComman";

export default class SubscribeService {
  static async getPlans() {
    return await http.get("/plans");
  }

  static async getCards() {
    return await http.get("/cards");
  }

  static async subscrubePlan(data: PlanSubscribe) {
    return await http.post("/subscriptions", data);
  }

  static async changeSubscrubePlan(data: PlanSubscribe) {
    return await http.put("/subscriptions", data);
  }

  static async getWalletBalance() {
    return await http.get("wallets");
  }

  static async chargeWalletBalance(data: any) {
    return await http.post("wallets", data);
  }

  static async getTransaction(data: string = "") {
    return await http.get(`transactions${data}`);
  }

  static async filterTransaction(data: string = "") {
    return await http.get(`transactions${data}`);
  }

  static async deletCard(id: string) {
    return await http.delete(`cards/${id}`);
  }

  static async unsubscripe(id: string) {
    return await http.delete(`subscriptions/${id}`);
  }

  static async setDefaultCard(id: string) {
    return await http.patch(`cards/${id}`);
  }

  static async addNewCard(data: any) {
    return await http.post("cards", data);
  }

  static async walletConfigInsert(data: any) {
    return await http.post("actions", { actions: data }, signal);
  }
  static async walletConfigGet() {
    return await http.get("actions", signal);
  }

  static async walletConfigUpdate(data: any) {
    return await http.put("actions", { actions: data }, signal);
  }
}
