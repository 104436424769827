import React from 'react'

type Props = {
    icon: string;
    style?:object
  };

const SVGIcon:React.FC<Props> = ({icon,style={}}) => {
    return (
        <img src={icon} style={style} alt="" />
    )
}

export default SVGIcon
