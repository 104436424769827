import { filter } from "lodash";
import { IntialStateTemplates, templateData } from "./type";
import { GET_TEMPLATES, GET_REPORT, CREATE_TEMPLATES, EDIT_TEMPLATES, DELETE_TEMPLATES, RESET_TEMPLATES } from "./types";


const initialState: IntialStateTemplates = {
  templates: [],
  currentTemplate: null
};

export default function templatesReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case GET_TEMPLATES: {
      return {
        ...state,
        templates: payload
      };
    }
    case CREATE_TEMPLATES:
      return {
        ...state,
        templates: [...state?.templates, payload]

      };
    case GET_REPORT: {
      return {
        ...state,
        currentTemplate: payload
      };
    }
    case EDIT_TEMPLATES:
      return {
        ...state,
        currentTemplate: payload
      };
      
    case DELETE_TEMPLATES: {
      return {
        ...state,
        templates: filter(state.templates, (item: templateData) => item?.TempKey !== payload)
      };
    }
    case RESET_TEMPLATES:{
      return{
        ...state,
        currentTemplate: null
      }
    }
    default:
      return state;
  }
}
