import { authsData } from "./type";
import { GET_OAUTHS, CREATE_OAUTH, DELETE_OAUTH, EDIT_OAUTH } from "./types";


interface IntialState {
  auths: { [keyAuth: string]: authsData }
}

const initialState: IntialState = {
  auths: {},
};

export default function integrationsReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_OAUTHS: {
      const data = ([...payload||[]]).reduce(
        (obj: { [keyAuth: string]: authsData }, item: authsData) => Object.assign(obj, { [item?.key || ""]: item }),
        {}
      )
      return {
        ...state,
        auths: data
      }
    }
    case CREATE_OAUTH: {
      return {
        ...state,
        auths: { ...state.auths, [payload?.key]: payload },
      }
    }
    case DELETE_OAUTH: {
      let newAuths: { [keyAuth: string]: authsData } = { ...state.auths }
      delete newAuths?.[payload]
      return {
        ...state,
        auths: newAuths,
      }
    }
    case EDIT_OAUTH: {
      return {
        ...state,
        auths: { ...state.auths, [payload?.key]: payload },
      }
    }
    default:
      return state;
  }
}