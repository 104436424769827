import { filter, isEmpty } from "lodash";
import { SurveyData, operationSurveyMs } from "./selector";
import {
  CREATE_SURVEY,
  DELETE_SURVEY,
  RESET_CUREENT_SURVEY,
  FILE_LINK,
  GET_SURVEY,
  RESEND_MSG_DATA,
  RESET_SURVEY,
  SELECTED_SURVEY,
  UPDATE_SURVEY,
  OPERATION,
  FILTER,
} from "./types";

interface IntialState {
  surveys: object[];
  TotalCampa: number;
  NextPageToken: string;
  selectedSurvey: object | null;
  currentSurvey: SurveyData | null;
  resendMsgData: null | object;
  link?: string;
  operation?: operationSurveyMs;
  filter: boolean;
}

const initialState: IntialState = {
  surveys: [],
  TotalCampa: 0,
  NextPageToken: "",
  selectedSurvey: null,
  currentSurvey: null,
  resendMsgData: null,
  filter: false,
};

export default function surveyReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case OPERATION:
      return {
        ...state,
        operation: payload || "",
      };
    case FILTER:
      return {
        ...state,
        filter: payload,
      };
    case CREATE_SURVEY:
      return {
        ...state,
        surveys: [payload, ...state.surveys],
      };
    case GET_SURVEY: {
      let surveys = payload?.Surveys ?? [];
      return {
        ...state,
        surveys: state.filter
          ? surveys
          : state.NextPageToken !== payload.NextPageToken ||
            payload.NextPageToken === ""
          ? [...state.surveys, ...surveys]
          : state.surveys,
        ...payload,
      };
    }
    case SELECTED_SURVEY: {
      let selectedSurvy: {
        Messages?: object[];
        CampaignID?: string;
        NextPageToken?: string;
      } | null = {
        ...state?.selectedSurvey,
      };

      if (
        selectedSurvy?.Messages &&
        selectedSurvy.CampaignID === payload?.CampaignID
      ) {
        selectedSurvy.Messages = [
          ...selectedSurvy.Messages,
          ...payload.Messages,
        ];
        selectedSurvy.NextPageToken = payload.NextPageToken;
      }

      return {
        ...state,
        selectedSurvey: !isEmpty(selectedSurvy) ? selectedSurvy : payload,
      };
    }
    case DELETE_SURVEY: {
      let newCapaign = state.surveys.filter(
        (item: any) => item.ID !== payload.ID
      );
      return {
        ...state,
        surveys: newCapaign,
        selectedSurvey: {},
        currentSurvey: null,
      };
    }
    case UPDATE_SURVEY: {
      let surveyState: any = state.surveys;
      let newSurvey = surveyState
        .map((item: any) => item.ID)
        .indexOf(payload.ID);
      let updatedSurvey = {
        ...payload,
        ChannelID: surveyState[newSurvey]?.ChannelID,
      };
      surveyState.splice(newSurvey, 1, updatedSurvey);
      return {
        ...state,
        surveys: surveyState,
      };
    }
    case RESEND_MSG_DATA: {
      return {
        ...state,
        resendMsgData: payload,
      };
    }
    case FILE_LINK:
      return {
        ...state,
        link: payload,
      };
    case RESET_SURVEY:
      return {
        ...state,
        NextPageToken: "",
        selectedSurvey: null,
        resendMsgData: null,
        currentSurvey: null,
        surveys: [],
        operation: "",
        filter: false,
      };
    case RESET_CUREENT_SURVEY:
      let res = filter([...state.surveys], (item: any) => item?.ID === payload);
      return {
        ...state,
        currentSurvey: res?.length > 0 ? res[0] : null,
        selectedSurvey: null,
      };
    default:
      return state;
  }
}
