import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as MenuToggleIcon } from "../../assets/general-icon/toggle-menu.svg";
import { AppContext } from "../../App";
import LeftDrawer from "./left-drawer/LeftDrawer";
import ElevationScroll from "./ElevationScroll";
import CssBaseline from "@mui/material/CssBaseline";
import { fixedNavData } from "./fixedData";
import { useLocation } from "react-router-dom";
import AvatarIcon from "./left-drawer/Avatar";
import { useSelector } from "react-redux";
import { authSelector, UserProfile } from "redux/auth/selector";
import { Routes } from "enums/routes";

interface Props {
  navRef?: React.RefObject<HTMLDivElement> | null | undefined;
}

export const MobileNavContext = createContext<any>([]);

const MobileNavbarContainer: React.FC<Props> = (props) => {
  const [showLeftDrawer, setShowLeftDrawer] = useState<boolean>(false);
  const [t, theme] = useContext(AppContext);
  const { pathname } = useLocation();
  const { profile }: UserProfile = useSelector(authSelector);

  const handleShowLeftDrawer: (value: boolean) => void = useCallback(
    (value) => {
      setShowLeftDrawer(value);
    },
    []
  );

  const value = useMemo(
    () => [handleShowLeftDrawer, showLeftDrawer],
    [handleShowLeftDrawer, showLeftDrawer]
  );
  return (
    <MobileNavContext.Provider value={value}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar ref={props.navRef} /* sx={{mb:'10em'}} */>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => handleShowLeftDrawer(true)}
            >
              <MenuToggleIcon fill={theme.palette.general.offWhite} />
            </IconButton>

            <LeftDrawer
              icons={fixedNavData(t, theme, pathname as Routes, profile).filter(
                (item) => item
              )}
            />
            <div>
              <AvatarIcon />
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </MobileNavContext.Provider>
  );
};

export default MobileNavbarContainer;
