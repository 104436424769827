import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AppContext } from "App";
import React, { useContext } from "react";
import { MenuItemType } from "./type";

interface Props {}

const NormalList: React.FC<Props & MenuItemType> = ({
  item,
  handleSetPageUrl,
}) => {
  const checkTabScreen = useMediaQuery("(min-width:700px)");
  const Icon: any = item.svgIcon;
  const [, theme] = useContext(AppContext);

  return (
    <MenuItem
      sx={{ height: checkTabScreen ? "5em" : "3.5em", ...item.listItemStyle }}
      onClick={() => handleSetPageUrl(item.value)}
    >
      {item.svgIcon && (
        <ListItemIcon sx={{ justifyContent: "space-around" }}>
          <Icon
            fill={theme.palette.general.iconSubColor}
            width={item.svgStyle.width}
          />
        </ListItemIcon>
      )}
      <Typography variant={item.variant} sx={item.textStyle}>
        {item.text}
      </Typography>
    </MenuItem>
  );
};

export default NormalList;
