import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";

import { AppContext } from "App";
import { languages } from "components/setting/constant";
import { AppConfig } from "enums/config";
import { handleLangChange } from "services/changeLang";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: AppConfig.BORDER_RADIUS_MIN,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: `0px 184.989px 147.992px rgba(0, 0, 0, 0.04),
    0px 55.7689px 44.6151px rgba(0, 0, 0, 0.0294434), 
    0px 23.1635px 18.5308px rgba(0, 0, 0, 0.0263823), 
    0px 8.3778px 6.70224px rgba(0, 0, 0, 0.0193761)`,
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      justifyContent: "end",
      padding: 0,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function LanguageButton() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeLanguage, setActiveLanguage] = React.useState<string | null>(
    null
  );
  const [t, theme, minPcMatch, , , , , , , , setDirection] =
    React.useContext(AppContext);
  const open = Boolean(anchorEl);

  const activeLang: {
    name?: string;
    icon?: string;
    value?: string;
  } = React.useMemo(() => {
    if (activeLanguage) {
      return languages(t).find((item) => item.value === activeLanguage) || {};
    }
    return languages(t)[0];
  }, [activeLanguage, t]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLang = (value: string) => {
    setActiveLanguage(value);
    handleLangChange(value);
    setDirection(value === "ar" ? "rtl" : "ltr");
    handleClose();
  };

  const fetchLang = React.useCallback(async () => {
    if (
      (await localStorage.hasOwnProperty("lang")) &&
      (await localStorage.getItem("lang")) !== null
    ) {
      const lang = await localStorage.getItem("lang");
      setActiveLanguage(lang);
      setDirection(lang === "ar" ? "rtl" : "ltr");
    }
  }, [setDirection]);

  React.useEffect(() => {
    fetchLang();
  }, [fetchLang]);

  return (
    <>
      <Button
        variant="text"
        id="lang-button"
        aria-controls={open ? "lang-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          p: minPcMatch ? "0" : "0 0 0 10px",
          textTransform: "none",
          fontSize: minPcMatch ? "1rem" : ".85rem",
          "&:hover": { bgcolor: "transparent" },
          color: minPcMatch
            ? theme.palette.general.offWhite
            : theme.palette.general.darkPrimary,
          fontWeight: 500,
          justifyContent: minPcMatch ? "flex-start" : "auto",
          "& .flag-icon>svg": {
            borderRadius: AppConfig.BORDER_RADIUS_MIN,
            width: 11,
            height: 11,
          },
          "& .MuiButton-endIcon": {
            marginRight: "8px",
          },
        }}
        disableRipple
        disableElevation
        fullWidth={minPcMatch}
        onClick={handleClick}
      >
        <span className="flag-icon" style={{ padding: "0 5px" }}>
          {t(activeLang?.name) || ""}
        </span>
        <img
          src={activeLang?.icon}
          loading="lazy"
          width={11}
          height={11}
          alt="language button"
        />
      </Button>
      <StyledMenu
        id="lang-menu"
        MenuListProps={{
          "aria-labelledby": "lang-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {languages(t).map((item: any) => (
          <MenuItem
            onClick={() => handleLang(item.value)}
            disableRipple
            key={item?.value}
          >
            {t(item?.name)}
            <img
              src={item?.icon}
              loading="lazy"
              width={11}
              height={11}
              style={{ margin: "2px 10px" }}
              alt="language button"
            />
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
