export enum ChannelStatus {
    ENABLED = "ENABLED",
    SUSPENDED = "SUSPENDED",
}
export enum ChannelOperations {
    GET = "get",
    EDIT = "edit"
}
export enum ChannelOperationsType {
    COPY="copy",
    ADD="add"
}