import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface Props {
  loaderStyle?: object;
  size?: number;
}

const Loader: React.FC<Props> = ({ loaderStyle = {}, size = 40 }) => {
  return (
    <Box sx={loaderStyle}>
      <CircularProgress disableShrink size={size} />
    </Box>
  );
};

export default Loader;
