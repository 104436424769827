export const GET_PLANS='GET_PLANS'
export const GET_CARDS='GET_CARDS'
export const SUBSCRIBE_PLAN = 'SUBSCRIBE_PLAN'
export const WALLET_BALANCE = 'WALLET_BALANCE'
export const TRANSACTION_GET = "TRANSACTION_GET"
export const TRANSACTION_FILTER = "TRANSACTION_FILTER"
export const RESET_TRANSACTION_FILTER = "RESET_TRANSACTION_FILTER"
export const DELETE_CARD = 'DELETE_CARD'
export const UNSUBSCRIBE_PLAN = 'UNSUBSCRIBE_PLAN'
export const SET_DEFAULT_CARD = 'SET_DEFAULT_CARD'
export const ADD_NEW_CARD = 'ADD_NEW_CARD'
export const UPDATE_SUBSCRIBE_PLAN = 'UPDATE_SUBSCRIBE_PLAN'
export const WALLET_CHARGE = "WALLET_CHARGE"
export const INSERT_WALLET_CONFIG = "INSERT_WALLET_CONFIG"
export const GET_WALLET_CONFIG = "GET_WALLET_CONFIG"
export const UPDATE_WALLET_CONFIG = "UPDATE_WALLET_CONFIG"
