import { IntialStateDashboard } from "./type";
import {
  GET_STATICS,
  GET_DATA_TABLE,
  UPDATE_STATICS,
  GET_HEADER_STATICS,
  EMPTY_TABLE,
} from "./types";
import { processDataTable } from "./utils";

const initialState: IntialStateDashboard = {
  statics: {},
  updatedStatics: [],
  table: { headCells: [], dataTable: [] },
  headerStatics: {
    CSAT: { value: 0 },
    agentTotalTimeOnline: { value: "0" },
  },
};

export default function dashboardReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case GET_STATICS: {
      return {
        ...state,
        statics: payload,
      };
    }
    case GET_DATA_TABLE: {
      const newData = processDataTable(payload);
      return {
        ...state,
        table: newData || {},
      };
    }
    case UPDATE_STATICS: {
      return {
        ...state,
        updatedStatics: payload || [],
      };
    }
    case GET_HEADER_STATICS: {
      return {
        ...state,
        headerStatics: payload,
      };
    }
    case EMPTY_TABLE: {
      return {
        ...state,
        table: { headCells: [], dataTable: [] },
      };
    }
    default:
      return state;
  }
}
