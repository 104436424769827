import { channelOperationdData, deleteChannelProps } from "redux/channels/type";
import http, { signal } from "../config/httpComman";

export default class channelService {
  static async getMyChannels() {
    return await http.request({
      url: "/channel",
      method: "post",
      params: {
        reqPayload: "channels",
        signal,
      },
    });
  }
  static async getEngines() {
    return await http.request({
      url: "/channel",
      method: "post",
      params: {
        reqPayload: "engines",
        signal,
      },
    });
  }
  static async createChannel(data: channelOperationdData) {
    return await http.request({
      url: "/channel",
      method: "post",
      params: {
        reqPayload: "login_link",
        ...data,
        // signal,
      },
    });
  }
  static async createChannelWithInformation(engine: object) {
    return await http.put("/channel", { ...engine });
  }
  static async deleteChannel(data: deleteChannelProps) {
    return await http.request({
      url: "/channel",
      method: "post",
      params: {
        reqPayload: "update",
        ...data,
        signal,
      },
    });
  }
  static async updateWhatsappChannel(data: any) {
    return await http.post("/whatsapp/profile", data);
  }

  static async updateSettingChannel(data: any) {
    return await http.post(`/channel?reqPayload=update&channelID=${data.channelID}`, data, signal)
  }
}
