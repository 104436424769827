import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { createContext, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UserProfile, authSelector } from "redux/auth/selector";
import person from "assets/img/noProfilePic.png";
import RightSectionDrawer from "../right-drawer/RightSectionDrawer";

interface Props {}

export const AvatarContext = createContext<any>([]);
const AvatarIcon = (props: Props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { profile }: UserProfile = useSelector(authSelector);

  const handleClick = useCallback((value: boolean) => {
    setShowDrawer(value);
  }, []);

  const value = useMemo(
    () => [showDrawer, handleClick],
    [showDrawer, handleClick]
  );

  return (
    <AvatarContext.Provider value={value}>
      <RightSectionDrawer />
      <Grid container>
        <Grid item xs={6} display="contents">
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            {/* <Notification /> */}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={() => handleClick(true)}
                size="small"
                sx={{ ml: 2 }}
              >
                <Avatar
                  alt="Profile Picture"
                  src={profile?.imageURL ?? person}
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </AvatarContext.Provider>
  );
};

export default AvatarIcon;
