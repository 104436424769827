import io from "socket.io-client";

export const socket = io(`${process.env.REACT_APP_SOCKET_API}`, {
  transports: ["websocket"],
  reconnectionDelay: 5000,
  reconnection: true,
  autoConnect: false,
});

/* export const videoSocket = io(`http://192.168.100.93:8080`, {
  transports: ["websocket"],
  reconnectionDelay: 5000,
  pingTimeout: 6000,
  reconnection: true,
}); */
