export const ADD_MESSAGE = "ADD_MESSAGE";
export const SEND_SUCCESS = "SEND_SUCCESS";
export const GET_PREDEFINE = "GET_PREDEFINE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const SELECT_MESSAGE = "SELECT_MESSAGE";
export const GET_HSM = "GET_HSM";
export const ADD_HSM = "ADD_HSM";
export const SEND_SUCCESS_HSM = "SEND_SUCCESS_HSM";
export const DELETE_HSM = "DELETE_HSM";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const LOADING_MEDIA="LOADING_MEDIA"
