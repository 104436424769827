import {
  Card,
  DetailsPlan,
  SubscribeIntialState,
  WalletAlertConfig,
  WalletConfig,
  WalletRenewConfig,
} from "./type";
import {
  DELETE_CARD,
  GET_CARDS,
  GET_PLANS,
  GET_WALLET_CONFIG,
  INSERT_WALLET_CONFIG,
  RESET_TRANSACTION_FILTER,
  SET_DEFAULT_CARD,
  SUBSCRIBE_PLAN,
  TRANSACTION_FILTER,
  TRANSACTION_GET,
  UNSUBSCRIBE_PLAN,
  UPDATE_SUBSCRIBE_PLAN,
  UPDATE_WALLET_CONFIG,
  WALLET_BALANCE,
  WALLET_CHARGE,
} from "./types";

const initialState: SubscribeIntialState = {
  plans: {},
  subscribedPlan: "",
  cards: {},
  walletBalance: "",
  balanceType: "",
  transactions: {
    transactions: [],
    totalNumber: 0,
    filter: false,
  },
  walletConfig: null,
};

const formatWalletConfig: (payload: WalletConfig) => any = (
  payload: WalletConfig
) => {
  let data = {};
  payload.forEach((item: WalletRenewConfig | WalletAlertConfig) => {
    data = {
      ...data,
      [item.type]: item,
    };
  });

  return data;
};

export default function subscribeReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_PLANS:
      let plans = {};
      payload?.plans?.forEach((plan: DetailsPlan) => {
        plans = {
          ...plans,
          [plan.id]: plan,
        };
      });
      return {
        ...state,
        plans,
        subscribedPlan: payload.currentPlan,
      };
    case GET_CARDS:
      let cards = {};
      payload.forEach((card: Card) => {
        cards = {
          ...cards,
          [card.cardID]: card,
        };
      });
      return {
        ...state,
        cards: cards,
      };
    case SUBSCRIBE_PLAN:
      return {
        ...state,
        subscribedPlan: payload.id,
        walletBalance: payload.walletBalance,
      };
    case UPDATE_SUBSCRIBE_PLAN:
      return {
        ...state,
        subscribedPlan: payload.id,
        walletBalance: payload.walletBalance,
      };
    case WALLET_BALANCE:
      return {
        ...state,
        walletBalance: payload.balance,
        balanceType: payload.type,
      };
    case TRANSACTION_GET:
      return {
        ...state,
        transactions: {
          transactions: payload.transactions,
          totalNumber:
            payload.totalNumber > 0
              ? payload.totalNumber
              : state.transactions.totalNumber,
          filter: false,
        },
      };
    case TRANSACTION_FILTER:
      return {
        ...state,
        transactions: {
          transactions: payload.transactions,
          totalNumber: state.transactions.totalNumber,
          filter: true,
        },
      };
    case RESET_TRANSACTION_FILTER:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          transactions: payload.transactions,
          filter: false,
        },
      };
    case DELETE_CARD: {
      let cards = state.cards;
      delete cards[payload];
      return {
        ...state,
        cards,
      };
    }
    case UNSUBSCRIBE_PLAN: {
      let subscribedPlan = state.subscribedPlan;
      subscribedPlan = "";
      return {
        ...state,
        subscribedPlan,
      };
    }
    case SET_DEFAULT_CARD: {
      let cards = state.cards;
      //   console.log({cards,payload})
      cards[payload.oldId].default = false;
      cards[payload.id].default = true;
      return {
        ...state,
        cards,
      };
    }
    // case WALLET_CHARGE: {
    //   return {
    //     ...state,
    //     walletConfig: payload,
    //   };
    // }
    case INSERT_WALLET_CONFIG:
      return {
        ...state,
        walletConfig: formatWalletConfig(payload),
      };
    case UPDATE_WALLET_CONFIG:
      return {
        ...state,
        walletConfig: formatWalletConfig(payload),
      };
    case GET_WALLET_CONFIG:
      return {
        ...state,
        walletConfig: formatWalletConfig(payload),
      };
    /*     case SUBSCRIBE_PLAN:
      return {
        ...state,
      };
    case UPDATE_SUBSCRIBE_PLAN:
      return {
        ...state,
      };
    case WALLET_BALANCE:
      return {
        ...state,
        walletBalance: payload.balance,
      };
    case TRANSACTION_GET:
      return {
        ...state,
        transactions: {
          transactions:payload.transactions,
          totalNumber:
            payload.totalNumber > 0
              ? payload.totalNumber
              : state.transactions.totalNumber,
            filter:false
        },
      };
    case TRANSACTION_FILTER:
        return {
            ...state,
            transactions: {
                transactions: payload.transactions,
                totalNumber: state.transactions.totalNumber,
                filter:true
              },
        }
    case RESET_TRANSACTION_FILTER:
        return {
            ...state,
            transactions: {
                ...state.transactions,
                transactions:payload.transactions,
                filter:false
              },
        }
    case DELETE_CARD: {
      let cards = state.cards;
      delete cards[payload];
      return {
        ...state,
        cards,
      };
    }
    case UNSUBSCRIBE_PLAN: {
      let subscribedPlan = state.subscribedPlan;
      subscribedPlan = "";
      return {
        ...state,
        subscribedPlan,
      };
    }
    case SET_DEFAULT_CARD: {
      let cards = state.cards;
      cards[payload.oldId].default = false;
      cards[payload.id].default = true;
      return {
        ...state,
        cards,
      };
    } */
    case WALLET_CHARGE: {
      return {
        ...state,
        walletConfig: payload,
      };
    }
    default:
      return state;
  }
}
