import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import { MenuItemType } from "./type";
import SVGIcon from "../../constant/SVGIcon";

interface Props {}

const DropDownList: React.FC<Props & MenuItemType> = ({ item }) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <MenuItem>
      <ListItemButton onClick={handleClick}>
        {item.svgIcon && (
          <ListItemIcon sx={{justifyContent:'space-around'}}>
            <SVGIcon icon={item.svgIcon} style={item.svgStyle} />
          </ListItemIcon>
        )}
        <ListItemText primary={item.text} sx={item.textStyle} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {item.subMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subMenu?.map(
              ({
                id,
                subText,
                subIcon = "",
                subIconStyle = {},
                subTextStyle = {},
              }) => (
                <ListItemButton sx={{ pl: 4 }} key={id}>
                  <ListItemIcon>
                    <SVGIcon icon={subIcon} style={subIconStyle} />
                  </ListItemIcon>
                  <ListItemText primary={subText} sx={subTextStyle} />
                </ListItemButton>
              )
            )}
          </List>
        </Collapse>
      )}
    </MenuItem>
  );
};

export default DropDownList;
