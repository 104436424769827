import { Avatar, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "App";
import person from "assets/img/noProfilePic.png";
import { useSelector } from "react-redux";
import { authSelector, UserProfile } from "redux/auth/selector";
interface Props {}

const UserInfo = (props: Props) => {
  const [, theme, minPcMatch, smallPcMatch, largPcMatch] =
    useContext(AppContext);
  const { profile }: UserProfile = useSelector(authSelector);
  return (
    <Grid
      container
      alignItems={"center"}
      alignContent={"center"}
      spacing={1}
      pt={minPcMatch ? ".5em" : "auto"}
      px={minPcMatch ? "1em" : "0"}
    >
      <Grid item alignContent={"center"} justifyContent={"center"} xs={"auto"}>
        <Avatar
          alt="Profile Picture"
          src={profile?.imageURL ?? person}
          sx={{
            width: "35px",
            height: "35px",
          }}
        />
      </Grid>
      <Grid item xs={"auto"}>
        <Typography
          variant={"body1"}
          component="div"
          title={profile.name}
          sx={{
            maxWidth: minPcMatch ? "auto" : "100%",
            width: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: minPcMatch
              ? theme.palette.general.offWhite
              : theme.palette.primary.main,
            fontWeight: 700,
            fontSize: smallPcMatch
              ? "1rem"
              : minPcMatch
              ? ".8rem"
              : largPcMatch
              ? ".9rem"
              : "1rem",
          }}
        >
          {profile.name ?? ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
